/* eslint-disable react/display-name */
import axios from 'axios'
import React from 'react'
import api from '../api'
import {
  LinkEpisodesList,
  Photos,
  PodcastFormTags,
  PodcastFormTeasers,
  PodcastReadCover,
  PodcastReadEpisodes,
  PodcastReadTeaser
} from '../components/automated/custom'
import AutomatedModalBatchDelete from '../components/automated/custom/AutomatedModalBatchDelete'
import config from '../config'
import { dataURItoBlob } from '../lib'
import { Categories } from './Categories'
import { Episodes } from './Episodes'

export const Podcasts = {
  title: 'podcasts.title',
  url: '/podcasts',
  submodules: [
    {
      url: '/podcasts/:id/episodes',
      model: Episodes,
    },
  ],
  list: {
    title: 'podcasts.list',
    table: [
      {
        head: 'podcasts.label.id',
        key: 'id',
        sortable: true,
        isDefault: true,
        width: '10%',
      },
      {
        head: 'podcasts.label.title',
        key: 'title',
        sortable: true,
        width: '30%',
      },
      {
        head: 'podcasts.label.abstract',
        key: 'abstract',
        sortable: true,
        width: '30%',
      },
      {
        head: 'podcasts.label.episodes',
        key: 'episodes',
        type: 'more',
        link: (id) => `/podcasts/${id}/episodes`,
        text: 'podcasts.seeEpisodes',
        width: '20%',
      },
      {
        head: 'podcasts.label.isPined',
        key: 'is_pinned',
        type: 'toggle',
        width: '10%',
        onChange: (podcast) => {
          if (podcast.is_pinned) {
            api.putPodcastPined(podcast.id)
          } else {
            api.deletePodcastPined(podcast.id)
          }
        },
        changeItems: (items, rowIndex) => items.map((item, index) => ({
          ...item,
          is_pinned: index === rowIndex,
        }))
      },
    ],
    batchActions: {
      title: 'batchActions.label.title',
      defaultValue: 'batchActions.label.defaultValue',
      submitButton: 'batchActions.label.submit',
      items: [
        {
          label: 'batchActions.delete.label',
          title: 'batchActions.delete.title',
          // eslint-disable-next-line react/display-name
          component: (props) => (<AutomatedModalBatchDelete {...props} />),
          submit: (ids) => {
            console.log('confirm batch deletion with ids', ids)
          },
        },
      ],
      getTitle: (item) => item.title,
    },
    getItems: (limit, offset, lang, sortType, sortDirection, props) => {
      console.log('loading data for podcasts with params', limit, offset, lang, sortType, sortDirection, props)
        return api.getPodcasts(limit, offset, sortType, sortDirection).then((d) => d.items)
    },
    limit: 20,
  },
  create: {
    title: 'podcasts.label.create',
    form: [
      {
        id: 'title',
        type: 'text',
        placeholder: 'podcasts.placeholder.title',
        label: 'podcasts.label.title',
        required: true,
      },
      {
        id: 'description',
        type: 'text',
        placeholder: 'podcasts.placeholder.description',
        label: 'podcasts.label.description',
        required: true,
      },
      {
        id: 'abstract',
        type: 'text',
        placeholder: 'podcasts.placeholder.abstract',
        label: 'podcasts.label.abstract',
        required: true,
      },
      {
        id: 'categories',
        type: 'checkboxes',
        model: Categories,
        placeholder: 'podcasts.placeholder.category',
        label: 'podcasts.label.category',
        required: true,
      },
      {
        id: 'tags',
        type: 'custom',
        component: (props) => (<PodcastFormTags {...props} />),
        label: 'podcasts.label.tags',
      },
      {
        type: 'row',
        items: [
          {
            id: 'thumbnail',
            type: 'custom',
            component: (props) => (<Photos {...props} flavour="alt" indications="Format : .jpg, 512x716px (taille réelle)" wording="ui.addPhoto" />),
            label: 'podcasts.label.thumbnail',
          },
          {
            id: 'square',
            type: 'custom',
            component: (props) => (<Photos {...props} flavour="square" indications="Format : .jpg, 264x264px (taille réelle)" wording="ui.addPhoto" />),
            label: 'podcasts.label.square',
          },
        ],
      },
      {
        id: 'teasers',
        type: 'custom',
        component: (props) => (<PodcastFormTeasers {...props} />),
        label: 'podcasts.label.teaser',
      },
      {
        type: 'row',
        items: [
          {
            type: 'back',
            label: 'ui.cancel',
            md: 1,
          },
          {
            type: 'submit',
            label: 'podcasts.label.submit',
            md: 2,
          },
        ],
      },

    ],
    submit: (data, lang) => {
      console.log('ready to create with', data, lang)
      return api.postPodcast({
        title: data.title,
        description: data.description,
        abstract: data.abstract,
      }).then((d) => {
        const promises = []

        promises.push(api.postPodcastCategories(d.id, {
          categories: data.categories,
          clean: true,
        }))

        if (data.tags) {
          promises.push(api.postPodcastTags(d.id, {
            tags: data.tags.map(tag => tag.name),
            clean: true,
          }))
        }
        if (data.thumbnail && data.thumbnail.img && data.thumbnail.type) {
          const token = localStorage.getItem('accessToken')
          const url = `${config.API_BASE_URL}podcasts/${d.id}/covers/alt`
          const blob = dataURItoBlob(data.thumbnail.img)
          promises.push(axios.post(
            url,
            blob,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': data.thumbnail.type,
              },
            },
          ))
        }

        if (data.teasers) {
          data.teasers.forEach((teaser) => {
            if (teaser.action === 'added') {
              const token = localStorage.getItem('accessToken')
              const url = `${config.API_BASE_URL}podcasts/${d.id}/teasers/content`
              const blob = dataURItoBlob(teaser.video)
              promises.push(axios.post(
                url,
                blob,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'video/mp4',
                  },
                },
              ))
            }
          })
        }

        if (data.square && data.square.img && data.square.type) {
          const token = localStorage.getItem('accessToken')
          const url = `${config.API_BASE_URL}podcasts/${d.id}/covers/square`
          const blob = dataURItoBlob(data.square.img)
          promises.push(axios.post(
            url,
            blob,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': data.square.type,
              },
            },
          ))
        }
        return Promise.all(promises)
      },
      (error) => {
        console.error(error)
        return Promise.reject(error)
      })
    },

  },
  read: {
    title: 'podcasts.read.title',
    form: [
      {
        id: 'title',
        type: 'text',
        label: 'podcasts.label.title',
        required: true,
      },
      {
        id: 'description',
        type: 'text',
        label: 'podcasts.label.description',
        required: true,
      },
      {
        id: 'abstract',
        type: 'text',
        label: 'podcasts.label.abstract',
        required: true,
      },
      {
        id: 'categories',
        type: 'text',
        label: 'podcasts.label.category',
        required: true,
      },
      {
        id: 'tags',
        type: 'text',
        label: 'podcasts.label.tags',
        required: true,
      },
      {
        id: 'episodes',
        type: 'custom',
        component: (props) => (<PodcastReadEpisodes {...props} />),
        label: 'podcasts.label.episodes',
        required: true,
      },
      {
        type: 'row',
        items: [
          {
            id: 'thumbnail',
            type: 'custom',
            component: (props) => (<PodcastReadCover {...props} flavour="alt" />),
            label: 'podcasts.label.thumbnail',
          },
          {
            id: 'square',
            type: 'custom',
            component: (props) => (<PodcastReadCover {...props} flavour="square" />),
            label: 'podcasts.label.square',
          },
        ],
      },
      {
        id: 'teaser',
        type: 'custom',
        component: (props) => (<PodcastReadTeaser {...props} />),
        label: 'podcasts.label.teaser',
        required: true,
      },
    ],
    getLink: (item) => item.id,
    getItem: (id) => api.getPodcast(id).then((d) => {
      const categories = d.categories ? d.categories.map((c) => c.label) : []
      const tags = d.tags ? d.tags.map((c) => c.name) : []
      const data = {
        ...d,
        categories: categories.join(', '),
        tags: tags.join(', '),
      }

      return data
    }),
  },
  update: {
    title: 'podcasts.update.title',
    form: [
      {
        id: 'link',
        type: 'custom',
        component: (props) => (<LinkEpisodesList {...props} />),
      },
      {
        id: 'title',
        type: 'text',
        placeholder: 'podcasts.placeholder.title',
        label: 'podcasts.label.title',
        required: true,
      },
      {
        id: 'description',
        type: 'text',
        placeholder: 'podcasts.placeholder.description',
        label: 'podcasts.label.description',
        required: true,
      },
      {
        id: 'abstract',
        type: 'text',
        placeholder: 'podcasts.placeholder.abstract',
        label: 'podcasts.label.abstract',
        required: true,
      },
      {
        id: 'categories',
        type: 'checkboxes',
        model: Categories,
        placeholder: 'podcasts.placeholder.category',
        label: 'podcasts.label.category',
      },
      {
        id: 'tags',
        type: 'custom',
        component: (props) => (<PodcastFormTags {...props} />),
        label: 'podcasts.label.tags',
        needToSubmit: async (id, data) => api.postPodcastTags(id, {
          tags: data.map(tag => tag.name),
          clean: true,
        }),
      },
      {
        type: 'row',
        items: [
          {
            id: 'thumbnail',
            type: 'custom',
            component: (props) => (<Photos {...props} flavour="alt" indications="Format : .jpg, 512x716px (taille réelle)" />),
            label: 'podcasts.label.thumbnail',
            needToSubmit: async (id, data) => {
              if (data && data.img && data.type) {
                const token = localStorage.getItem('accessToken')
                const url = `${config.API_BASE_URL}podcasts/${id}/covers/alt`
                const blob = dataURItoBlob(data.img)
                return axios.post(
                  url,
                  blob,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'Content-Type': data.type,
                    },
                  },
                )
              }
            },
          },
          {
            id: 'square',
            type: 'custom',
            component: (props) => (<Photos {...props} flavour="square" indications="Format : .jpg, 264x264px (taille réelle)" />),
            label: 'podcasts.label.square',
            needToSubmit: async (id, data) => {
              if (data && data.img && data.type) {
                const token = localStorage.getItem('accessToken')
                const url = `${config.API_BASE_URL}podcasts/${id}/covers/square`
                const blob = dataURItoBlob(data.img)
                return axios.post(
                  url,
                  blob,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'Content-Type': data.type,
                    },
                  },
                )
              }
            },
          },
        ]
      },
      {
        id: 'teasers',
        type: 'custom',
        component: (props) => (<PodcastFormTeasers {...props} />),
        label: 'podcasts.label.teaser',
        needToSubmit: async (id, data) => {
          const promises = []
          if (!data) {
            return
          }

          data.forEach((teaser) => {
            if (teaser.action === 'deleted') {
              promises.push(api.deletePodcastTeaser(id, teaser.teaser))
            } else if (teaser.action === 'added') {
              const token = localStorage.getItem('accessToken')
              const url = `${config.API_BASE_URL}podcasts/${id}/teasers/content`
              const blob = dataURItoBlob(teaser.video)
              promises.push(axios.post(
                url,
                blob,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'video/mp4',
                  },
                },
              ))
            }
          })

          return Promise.all(promises)
        },
      },
      {
        type: 'row',
        items: [
          {
            type: 'back',
            label: 'ui.cancel',
            md: 1,
          },
          {
            type: 'submit',
            label: 'podcasts.label.submit',
            md: 2,
          },
        ],
      },
    ],
    photoUrl: (id, addBaseUrl = true, flavour, format = '.jpg') => {
      const token = localStorage.getItem('accessToken')
      return `${addBaseUrl ? config.API_BASE_URL : ''}podcasts/${id}/covers/${flavour}${format}?authorization=${token}`
    },
    getLink: (item) => item.id,
    getItem: (id) => api.getPodcast(id).then((d) => ({
      ...d,
      categories: d.categories ? d.categories.map((c) => c.id) : [],
    })),
    submit: (id, data, lang) => {
      console.log('ready to update', id, 'with', data, lang)
      return api.putPodcast(id, {
        title: data.title,
        description: data.description,
        abstract: data.abstract,
      }).then(() => api.postPodcastCategories(id, {
        categories: data.categories,
        clean: true,
      }))
    },
  },
  delete: {
    text: 'podcasts.delete.confirm',
    getTitle: (item) => item.title,
    submit: (item) => {
      console.log('delete item', item)
      const promise = new Promise((resolve) => {
        api.deletePodcast(item.id).then(() => {
          resolve(true)
        }, () => {
          resolve(false)
        })
      })
      return promise
    },
  },
}
