import React from 'react'
import { Redirect } from 'react-router-dom'

// Layout Types
import { DefaultLayout, LoginLayout } from './layouts'

// Route Views
import UserDownloader from './views/UserDownloader'
import ComponentsOverview from './views/ComponentsOverview'
import Login from './views/Login'
import Automated from './views/Automated'
import {
  Podcasts,
  Categories,
  EditoHightlight,
  Contributors,
} from './models'

const component = () => <Redirect to="/podcasts" />

const routes = [
  {
    path: '/',
    exact: true,
    layout: DefaultLayout,
    component,
  },
  {
    path: '/login',
    layout: LoginLayout,
    component: Login,
    public: true,
  },
  {
    path: '/podcasts',
    layout: DefaultLayout,
    component: Automated,
    model: Podcasts,
  },
  {
    path: '/categories',
    layout: DefaultLayout,
    component: Automated,
    model: Categories,
  },
  {
    path: '/highlight',
    layout: DefaultLayout,
    component: Automated,
    model: EditoHightlight,
  },
  {
    path: '/users',
    layout: DefaultLayout,
    component: UserDownloader,
  },
  {
    path: '/contributors',
    layout: DefaultLayout,
    component: Automated,
    model: Contributors,
  },
  {
    path: '/components-overview',
    layout: DefaultLayout,
    component: ComponentsOverview,
  },
]

export default routes
