import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import fr from './fr.json'
import en from './en.json'

const resources = {
  fr,
  en,
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: navigator.language || navigator.userLanguage,
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
