import React, { useEffect, useState } from 'react'
import { ReactVideo } from 'reactjs-media'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import api from '../../../api'
import config from '../../../config'

const PodcastReadTeaser = () => {
  const { id } = useParams()
  const [teasers, setTeasers] = useState([])
  const { t } = useTranslation()
  const token = localStorage.getItem('accessToken')

  useEffect(() => {
    api.getPodcastTeasers(id).then((data) => {
      const ts = []
      console.log(data)
      data.forEach((teaser) => {
        const tr = api.getPodcastTeaser(id, teaser, token)
        ts.push(tr)
      })
      setTeasers(ts)
    })
  }, [])

  return (
    <div className="category-container">
      {teasers.map((teaser, index) => (
        <div
          key={`teaser${index}`}
          style={{ marginBottom: '20px' }}
        >
          <ReactVideo
            src={`${config.API_BASE_URL}${teaser}`}
            poster="/assets/poster.png"
            primaryColor="blue"
            // other props
          />
          <a href={`${config.API_BASE_URL}${teaser}`} rel="noreferrer" target="_blank">
            {t('podcasts.downloadTeaser')}
          </a>
        </div>
      ))}
    </div>
  )
}

export default PodcastReadTeaser
