import api from '../api'

export const SubCategories = {
  populateForm: () => api.getCategories().then((categories) => {
    const cat = []
    categories.items.forEach((category) => {
      cat.push({ value: category.id, label: category.label, visible: category.visible })
    })
    console.log(cat)
    return cat
  }),
}
