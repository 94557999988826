import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Nav, NavItem, NavLink } from 'shards-react'
import { Link } from 'react-router-dom'

const MainFooter = ({ contained, menuItems }) => (
  <footer className="main-footer d-flex p-2 px-3 bg-white border-top">
    <Container fluid={contained}>
      <Row>
        <Nav>
          {menuItems.map((item, idx) => (
            <NavItem key={idx}>
              <NavLink tag={Link} to={item.to}>
                {item.title}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </Row>
    </Container>
  </footer>
)

MainFooter.propTypes = {
  /**
   * Whether the content is contained, or not.
   */
  contained: PropTypes.bool,
  /**
   * The menu items array.
   */
  menuItems: PropTypes.arrayOf(PropTypes.any),
}

MainFooter.defaultProps = {
  contained: false,
  menuItems: [
    {
      title: 'Conditions générales',
      to: '#',
    },
    {
      title: 'Support',
      to: '#',
    },
  ],
}

export default MainFooter
