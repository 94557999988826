import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import api from '../../../api'
import config from '../../../config'

const PodcastEpisodeReadCover = ({ flavour }) => {
  const { subId, id } = useParams()
  const [link, setLink] = useState('')
  const token = localStorage.getItem('accessToken')

  useEffect(() => {
    api.getPodcastEpisodeCover(id, subId, flavour, 'png', token).then((l) => {
      setLink(l)
    })
  }, [])

  return (
    <div className="category-container">
      <img src={`${config.API_BASE_URL}${link}`} alt="" />
    </div>
  )
}

PodcastEpisodeReadCover.propTypes = {
  flavour: PropTypes.oneOf(['default', 'alt']).isRequired,
}

export default PodcastEpisodeReadCover
