/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { useLazyQuery, useMutation } from '@apollo/client'
import { FieldsOnCorrectTypeRule } from 'graphql'
import i18n from 'i18next'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import {
  useHistory,
  useLocation,
  useParams
} from 'react-router-dom'
import {
  Button, Card, CardBody,
  CardFooter, CardHeader, Col, FormCheckbox,
  FormSelect, Row
} from 'shards-react'
import config from '../../../config'
import AutomatedModalDelete from './AutomatedModalDelete'

Modal.setAppElement('#root')

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '50px',
  },
}

const AutomatedList = (props) => {
  const { model, addTo, close, dataToShow, added } = props

  console.log('dataToShow', dataToShow)
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const { search, state } = location
  const query = new URLSearchParams(search)
  const page = parseInt(query.get('page'), 10) || 0
  const { limit, table } = model.list
  const { id } = useParams()
  const { pathname } = useLocation()
  console.log('automated list', props)

  let defaultSortType = ''
  table.forEach((th) => {
    if (th.isDefault) {
      defaultSortType = typeof th.sortable === 'string' ? th.sortable : th.key
    }
  })

  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState(dataToShow || [])
  const [modalIsOpen, setIsOpen] = useState(false)
  const [currentItem, setCurrentItem] = useState({})
  const [sortType, setSortType] = useState(defaultSortType)
  const [sortDirection, setSortDirection] = useState('asc')
  const [batchIds, setBatchIds] = useState([])
  const [batchAction, setBatchAction] = useState()
  const [showBatchModal, setShowBatchModal] = useState(false)
  const [pageHasPrev, setPageHasPrev] = useState(false)
  const [pageHasNext, setPageHasNext] = useState(false)
  const [offset, setOffset] = useState(0)
  const [noBatchIdSelected, setNoBatchIdSelected] = useState(false)
  const [allRowSelected, setAllRowSelected] = useState(false)
  const [batchDelete, setBatchDelete] = useState(false)
  const [totalPage, setTotalPage] = useState(0)
  const [isCreated, setIsCreated] = useState(state ? state.create : false)
  const [isUpdated, setIsUpdated] = useState(state ? state.update : false)
  const [isDelete, setIsDelete] = useState(false)
  const [isDeletedError, setIsDeletedError] = useState(false)
  const [isAdded, setIsAdded] = useState(false)

  if (state) {
    delete state.create
    delete state.update
  }

  if (config.CONNECTOR === 'graphql' && model.graphql) {
    // eslint-disable-next-line no-unused-vars
    const [fetchGrahQlItems] = useLazyQuery(model.list.query, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: !model.graphql ? {} : model.list.variables(
      limit,
      offset,
      i18n.language,
      sortType,
      sortDirection,
      props,
    ),
    onCompleted: (data) => {
      setItems(model.list.transformQuery(data))
      setLoading(false)
      setPageHasPrev(model.list.hasPreviousPage(data))
      setPageHasNext(model.list.hasNextPage(data))
      setTotalPage(model.list.totalPage(data))
    },
  })
}

  const loadData = async (refresh = false) => {
    setLoading(true)
    setBatchIds([])
    if (config.CONNECTOR === 'graphql' && model.graphql) {
      // eslint-disable-next-line no-undef
      fetchGrahQlItems()
    } else {
      try {
        const itemsFromApi = await model.list.getItems(
          limit,
          offset,
          i18n.language,
          sortType,
          sortDirection,
          { ...props, id },
        )

        if (model.list.orderable) {
            const itms = [
              ...itemsFromApi,
            ]
            setItems(itms)
        } else {
            setItems(itemsFromApi)
          }

        setLoading(false)
        setPageHasPrev(page > 0)
        setPageHasNext(itemsFromApi.length === limit)
        if (model.list.orderable && itemsFromApi.length === limit) {
          setOffset(offset + limit)
        }
      } catch (e) {
        setLoading(false)
        if (!model.list.orderable) {
          setItems([])
        }
        setPageHasPrev(page > 0)
        setPageHasNext(false)
      }
    }
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  let deleteUser
  let mutationLoading
  let mutationError
  if (model.delete && config.CONNECTOR === 'graphql' && model.graphql) {
    [deleteUser,
      { loading: mutationLoading, error: mutationError },
    ] = useMutation(model.delete.query, {
      variables: !model.graphql ? {} : {
        id: currentItem.id,
      },
      onCompleted: () => {
        setCurrentItem({})
        closeModal()
        loadData()
        setShowBatchModal(false)
      },
    })
  }

  let deleteBatchUser
  let mutationBatchLoading
  let mutationBatchError
  if (model.delete && config.CONNECTOR === 'graphql' && model.graphql) {
    [deleteBatchUser,
      { loading: mutationBatchLoading, error: mutationBatchError },
    ] = useMutation(model.delete.query, {
      variables: !model.graphql ? {} : {
        id: batchIds[0],
      },
      onCompleted: () => {
        const ids = [
          ...batchIds,
        ]

        ids.shift()
        setBatchIds(ids)

        if (ids.length === 0) {
          setShowBatchModal(false)
          setBatchDelete(false)
          loadData()
          closeModal()
        }
      },
    })
  }

  const deleteRestUser = () => {
    model.delete.submit({ id: batchIds[0], parentId: id }).then((resp) => {
      if (resp) {
        const ids = [
          ...batchIds,
        ]
        ids.shift()
        setBatchIds(ids)
        if (ids.length === 0) {
          setShowBatchModal(false)
          setBatchDelete(false)
          setIsDelete(true)
          setIsDeletedError(false)
          loadData()
          closeModal()
        }
      } else {
        setIsDelete(false)
        setIsDeletedError(true)
        closeModal()
      }
    })
  }

  useEffect(() => {
    if (batchDelete && batchIds.length > 0) {
      if (config.CONNECTOR === 'graphql' && model.graphql) {
        deleteBatchUser()
      } else if (model.delete) {
        deleteRestUser()
      }
    }
  }, [batchIds, batchDelete])

  useEffect(() => {
    if (!dataToShow || dataToShow.length === 0) {
      loadData()
    } else {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (!dataToShow || dataToShow.length === 0) {
      loadData()
    } else {
      setLoading(false)
    }
  }, [offset, sortType, sortDirection])

  useEffect(() => {
    let offst = page * limit
    if (page - 1 >= 0) {
      offst = page * limit
    }
    setOffset(offst)
    if (page !== 0) {
      setIsCreated(false)
      setIsUpdated(false)
    }
  }, [page])

  const styles = {
    button: {
      border: '0 none',
      background: 'none',
      color: 'rgb(90, 97, 105)',
      fontSize: '13px',
      fontWeight: 700,
    },
  }

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const grid = 8

  const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none', // some basic styles to make the items look a bit nicer
  background: isDragging ? 'lightgreen' : 'white', // change background colour if dragging
  ...draggableStyle, // styles we need to apply on draggables
  })

  const getListStyle = isDraggingOver => ({
    background: 'transparent',
    width: '100%',
  })

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    let itms = reorder(
      items,
      result.source.index,
      result.destination.index,
    )

    if (typeof model.list.reorder === 'function') {
      itms = model.list.reorder(itms, result.destination.index)
    }
    setItems(itms)
    model.list.orderable(itms)
  }

  const addContentFromSearch = (newContent, direction) => {
    const itms = [
      ...items,
    ]

    if (direction === 'top') {
      itms.unshift(newContent)
    } else {
      itms.push(newContent)
    }

    setItems(itms)
    model.list.search.addContent(itms, id).then(() => {
      setIsAdded(true)
    })
  }

  const deactivateAddedNotif = () => {
    setIsAdded(false)
  }

  return (
    <>
      <Row>
        <Col>
          <Card small className="mb-4">
            {!addTo && (
              <CardHeader className="border-bottom">
                {model.parentUrl && (
                  <p>
                    <a href={model.parentUrl}>
                      &larr;
                      &nbsp;
                      {t('ui.back')}
                    </a>
                  </p>
                )}
                <Row>
                  <Col>
                    <h6 className="m-0">
                      {t(model.list.title)}
                    </h6>
                  </Col>
                  <Col>
                    {model.list.search && model.list.search.component({ model, addContentFromSearch, isAdded, deactivateAddedNotif })}
                  </Col>
                </Row>

                {isCreated && (
                  <p className="btn-success btn-sm">{t('ui.create')}</p>
                )}

                {isUpdated && (
                  <p className="btn-success btn-sm">{t('ui.update')}</p>
                )}

                {isDelete && (
                  <p className="btn-success btn-sm">{t('ui.delete')}</p>
                )}

                {isDeletedError && (
                  <p className="btn-danger btn-sm">{t('ui.deleteError')}</p>
                )}

                <Col>
                  {false && model.create && (
                    <Button
                      theme="primary"
                      className="mb-2 mr-1"
                      onClick={() => {
                          history.push(`${pathname}/create`)
                        }}
                    >
                      {t('ui.add')}
                    </Button>
                  )}
                  {model.list.batchActions && model.list.batchActions.items.length === 1 && (
                    <>
                      <label htmlFor="feBatchActions">{t(model.list.batchActions.title)}</label>
                      <Row>
                        <Col>
                          <Button
                            theme="primary"
                            className="mb-2 mr-1"
                            onClick={() => {
                              const action = model.list.batchActions.items[0]
                              if (action && batchIds.length > 0) {
                                if (action.component) {
                                  setBatchAction(0)
                                  setShowBatchModal(true)
                                  setIsOpen(true)
                                } else {
                                  action.submit(batchIds)
                                }
                              } else {
                                setNoBatchIdSelected(true)
                              }
                            }}
                          >
                            {t(model.list.batchActions.items[0].label)}
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                  {noBatchIdSelected && (<p>{t('form.noBatchIdSelected')}</p>)}
                </Col>

                {model.list.batchActions && model.list.batchActions.items.length > 1 && (
                  <>
                    <label htmlFor="feBatchActions">{t(model.list.batchActions.title)}</label>
                    <Row>
                      <Col>
                        <FormSelect
                          id="feBatchActions"
                          onChange={((event) => {
                            setBatchAction(event.target.value)
                          })}
                        >
                          <option>{t(model.list.batchActions.defaultValue)}</option>
                          {model.list.batchActions.items.map((option, index) => (
                            <option value={index} key={`option${index}`}>{option.label}</option>
                          ))}
                        </FormSelect>
                      </Col>
                      <Col>
                        <Button
                          type="submit"
                          onClick={() => {
                            const action = model.list.batchActions.items[batchAction]
                            if (action && batchIds.length > 0) {
                              if (action.component) {
                                setShowBatchModal(true)
                                setIsOpen(true)
                              } else {
                                action.submit(batchIds)
                              }
                            }
                          }}
                        >
                          {t(model.list.batchActions.submitButton)}
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </CardHeader>
            )}
            <CardBody className="p-0 pb-3">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        background: '#f9f9f9',
                        padding: '15px 0',
                        borderBottom: '2px solid #e8e8ea',
                      }}
                      >
                        {!addTo && model.list.batchActions && (
                          <div style={{ padding: '0 10px' }}>
                            <FormCheckbox
                              checked={allRowSelected}
                              onChange={(() => {
                                const ids = []
                                if (!allRowSelected) {
                                  items.forEach((item) => {
                                    ids.push(item.id)
                                  })
                                }
                                setBatchIds(ids)
                                setAllRowSelected(!allRowSelected)
                                setNoBatchIdSelected(false)
                              })}
                            />
                          </div>
                        )}
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                          {model.list.table.map((item, index) => (
                            <div className="border-0" key={`th${index}`} style={{ width: item.width, padding: '0 10px' }}>
                              {item.sortable && !model.list.orderable && !addTo ? (
                                <button
                                  type="button"
                                  style={{
                                  ...styles.button,
                                  ...((sortType === item.key || sortType === item.sortable) ? { color: '#007bff' } : {}),
                                }}
                                  onClick={() => {
                                  let direction = sortDirection
                                  if (sortType === item.key || sortType === item.sortable) {
                                    direction = sortDirection === 'asc' ? 'desc' : 'asc'
                                    setSortDirection(direction)
                                  }
                                  setSortType(typeof item.sortable === 'string' ? item.sortable : item.key)
                                  history.push(pathname)
                                }}
                                >
                                  {t(item.head)}
                                  {(sortType === item.key || sortType === item.sortable) && (
                                  <>
                                    {' '}
                                    { sortDirection === 'asc' ? (<>&uarr;</>) : (<>&darr;</>)}
                                  </>
                                )}
                                </button>
                            ) : (
                              <>
                                {t(item.head)}
                              </>
                            )}
                            </div>
                        ))}
                        </div>
                        {(model.delete || model.update || model.read) && (
                          <div style={{ minWidth: '120px' }} />
                        )}
                        {addTo && added && (
                        <div style={{ height: 25, justifyContent: 'left', alignItems: 'center' }}>
                          <p
                            className="btn-success btn-sm"
                            style={{ margin: 2, left: 90, height: 20, padding: '0 10px' }}
                          >
                            {t('ui.added')}
                          </p>
                        </div>
                        )}
                        {addTo && (
                          <div style={{ height: 50, justifyContent: 'right', alignItems: 'center' }}>
                            <button
                              type="button"
                              style={{ background: 'none', border: '0 none' }}
                              onClick={() => {
                                close()
                                deactivateAddedNotif()
                              }}
                            >
                              x
                              {' '}
                              {t('ui.closeAddTo')}
                            </button>
                          </div>
                        )}
                      </div>
                      {!loading && items.map((row, rowIndex) => (
                        <Draggable key={`tr${row.id}`} draggableId={`row${row.id}`} index={rowIndex}>
                          {(provided1, snapshot1) => (
                            <div
                              ref={provided1.innerRef}
                              {...provided1.draggableProps}
                              {...provided1.dragHandleProps}
                              style={getItemStyle(
                                snapshot1.isDragging,
                                  provided1.draggableProps.style,
                                )}
                            >
                              <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                padding: '10px 0',
                                borderBottom: '1px solid #ccc',
                              }}
                              >
                                {!addTo && model.list.batchActions && (
                                  <div style={{ padding: '0 10px' }}>
                                    <FormCheckbox
                                      checked={batchIds.indexOf(row.id) !== -1}
                                      onChange={(() => {
                                        const ids = [
                                          ...batchIds,
                                        ]
                                        const index = ids.indexOf(row.id)
                                        if (index !== -1) {
                                          ids.splice(index, 1)
                                        } else {
                                          ids.push(row.id)
                                        }
                                        setBatchIds(ids)
                                        // setNoBatchIdSelected(false)
                                        // setAllRowSelected(false)
                                      })}
                                    />
                                  </div>
                                )}
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                                  {model.list.table.map((col, colIndex) => {
                                    let value = row[col.key]
                                    if (col.type === 'more') {
                                      return (
                                        <div style={{ width: col.width, padding: '0 10px' }}>
                                          <a href={col.link(row.id)}>{t(col.text)}</a>
                                        </div>
                                      )
                                    }
                                    if (col.type === 'toggle') {
                                      return (
                                        <div style={{ width: col.width, padding: '0 10px' }}>
                                          <FormCheckbox
                                            toggle
                                            checked={row.visible || row.is_pinned}
                                            onChange={async () => {
                                              const cat = {
                                                ...row,
                                                visible: !row.visible,
                                                is_pinned: !row.is_pinned
                                              }
                                              col.onChange(cat)
                                              if (row.is_pinned) {
                                               setItems(col.changeItems(items, -1))
                                              } else if (col.changeItems) {
                                                setItems(col.changeItems(items, rowIndex))
                                              } else {
                                                const itms = [
                                                  ...items,
                                                ]
                                                itms[rowIndex] = cat
                                                setItems(itms)
                                              }
                                          }}
                                          />
                                        </div>
                                      )
                                    }
                                    if (col.key.indexOf('.') !== -1) {
                                      const splitedKey = col.key.split('.')
                                      value = row[splitedKey[0]][splitedKey[1]]
                                    }
                                    return (
                                      <div key={`td${colIndex}`} style={{ width: col.width, padding: '0 10px' }}>{value}</div>
                                    )
                                  })}
                                </div>
                                {(model.delete || model.update || model.read) && !addTo && (
                                  <div style={{ width: '120px', textAlign: 'center' }}>
                                    {model.update && (
                                    <button
                                      type="button"
                                      style={{ background: 'none', border: '0 none' }}
                                      onClick={() => {
                                          history.push(`${pathname}/edit/${model.update.getLink(row)}`)
                                        }}
                                    >
                                      <i className="material-icons" style={{ width: '20px', textAlign: 'center' }}>edit</i>
                                    </button>
                                        )}
                                    {model.read && (
                                    <button
                                      type="button"
                                      style={{ background: 'none', border: '0 none' }}
                                      onClick={() => {
                                          history.push(`${pathname}/read/${model.read.getLink(row)}`)
                                        }}
                                    >
                                      <i className="material-icons" style={{ width: '20px', textAlign: 'center' }}>visibility</i>
                                    </button>
                                        )}
                                    {model.delete && (
                                    <button
                                      type="button"
                                      style={{ background: 'none', border: '0 none' }}
                                      onClick={() => {
                                          setIsOpen(true)
                                          setCurrentItem(row)
                                        }}
                                    >
                                      <i className="material-icons" style={{ width: '20px', textAlign: 'center' }}>delete</i>
                                    </button>
                                        )}
                                  </div>
                                )}
                                {addTo && (
                                  <div style={{ marginRight: 10 }}>
                                    <button
                                      type="button"
                                      style={{ background: 'none', border: '0 none' }}
                                      onClick={() => {
                                        addTo(row, 'top')
                                      }}
                                    >
                                      &uarr;
                                      {' '}
                                      {t('ui.addToTop')}
                                    </button>
                                    <br />
                                    <button
                                      type="button"
                                      style={{ background: 'none', border: '0 none' }}
                                      onClick={() => {
                                        addTo(row, 'bottom')
                                      }}
                                    >
                                      &darr;
                                      {' '}
                                      {t('ui.addToBottom')}
                                    </button>
                                  </div>
                                )}
                              </div>

                              {provided.placeholder}
                            </div>
                            )}
                        </Draggable>
                      ))}
                      {items.length === 0 && !loading && (
                        <div style={{ padding: 10 }}>{t('ui.noData')}</div>
                      )}
                      {loading && (
                        <div style={{ padding: 10 }}>{t('ui.loadingData')}</div>
                      )}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </CardBody>
            {!model.list.orderable && !addTo && (
              <CardFooter className="border-top">
                <Row>
                  <Col className="">
                    {t('ui.page')}
                    {' '}
                    { page + 1 || 1 }
                    { totalPage > 0 && (
                      <span>
                        {' '}
                        /
                        {' '}
                        { totalPage }
                      </span>
                    )}
                  </Col>

                  <Col className="text-right">
                    {pageHasPrev && (
                      <Button
                        theme="primary"
                        className="mb-2 mr-1"
                        onClick={() => {
                          history.push(`${pathname}?page=${page - 1}`)
                        }}
                      >
                        &larr;
                        {' '}
                        {t('ui.previous')}
                      </Button>
                    )}

                    {pageHasNext && (
                      <Button
                        theme="primary"
                        className="mb-2 mr-1"
                        onClick={() => {
                          history.push(`${pathname}?page=${page + 1}`)
                        }}
                      >
                        {t('ui.next')}
                        {'  '}
                        &rarr;
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
      {(model.delete || model.list.batchActions) && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
        >
          {model.delete && !showBatchModal && (
            <AutomatedModalDelete
              {...{ model, currentItem }}
              closeModal={(withDeletion = false) => {
                if (withDeletion) {
                  if (config.CONNECTOR === 'graphql' && model.graphql) {
                    deleteUser()
                  } else if (model.delete) {
                    model.delete.submit({
                      ...currentItem,
                      parentId: id,
                    }).then((resp) => {
                      if (resp) {
                        setIsDelete(true)
                        setIsDeletedError(false)
                      } else {
                        setIsDelete(false)
                        setIsDeletedError(true)
                      }
                      closeModal()
                      loadData()
                    })
                  } else {
                    closeModal()
                    loadData()
                  }
                } else {
                  setCurrentItem({})
                  closeModal()
                }
              }}
              mutationLoading={mutationLoading}
              mutationError={mutationError}
            />
          )}
          {model.list.batchActions && batchAction !== null && showBatchModal && model.list.batchActions.items[batchAction] && (
            <>
              {model.list.batchActions.items[batchAction].component({
                batchAction: model.list.batchActions.items[batchAction],
                items,
                batchIds,
                model,
                closeModal: (withDeletion = false) => {
                  if (withDeletion) {
                    setBatchDelete(true)
                    closeModal()
                    loadData(true)
                  } else {
                    setShowBatchModal(false)
                    closeModal()
                  }
                },
                mutationBatchLoading,
                mutationBatchError,
              })}
            </>
          )}
        </Modal>
      )}
    </>
  )
}

AutomatedList.propTypes = {
  model: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  addTo: PropTypes.func,
  close: PropTypes.func,
  dataToShow: PropTypes.arrayOf(PropTypes.any),
  added: PropTypes.bool,
}

AutomatedList.defaultProps = {
  addTo: null,
  close: null,
  added: false,
  dataToShow: [],
}

export default AutomatedList
