import jwtDecode from 'jwt-decode'
import dayjs from 'dayjs'

export const isUserTokenExpired = (token) => {
  if (token) {
    const { exp } = jwtDecode(token)
    return !exp || (dayjs().isAfter(dayjs(exp * 1000)))
  }
  return true
}

export const dataURItoBlob = (dataURL) => {
    const BASE64_MARKER = ';base64,'
    if (dataURL.indexOf(BASE64_MARKER) === -1) {
      const parts = dataURL.split(',')
      const contentType = parts[0].split(':')[1]
      const raw = parts[1]

      // eslint-disable-next-line no-undef
      return new Blob([raw], { type: contentType })
    }
    const parts = dataURL.split(BASE64_MARKER)
    const contentType = parts[0].split(':')[1]
    const raw = window.atob(parts[1])
    const rawLength = raw.length
    const uInt8Array = new Uint8Array(rawLength)

    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i)
    }

    // eslint-disable-next-line no-undef
    return new Blob([uInt8Array], { type: contentType })
  }
