import React, { useState } from 'react'
import {
  Button,
} from 'shards-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const AutomatedModalConfirmation = ({ closeModal, item, id, data }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  return (
    <>
      {!success && (
        <p style={{ textAlign: 'center' }}>
          {t(item.confirmationTitle)}
        </p>
      )}
      {loading && (<p>{t('ui.loading')}</p>)}
      {error && (<p>{t(item.confirmationError)}</p>)}
      {success && (<p>{t(item.confirmationSuccess)}</p>)}
      <div style={{ textAlign: 'center' }}>
        {!success && (
          <Button
            theme="danger"
            className="mb-2 mr-1"
            onClick={() => {
              closeModal()
            }}
          >
            {t('ui.deleteNo')}
          </Button>
        )}

        {!success && (
          <Button
            theme="primary"
            className="mb-2 mr-1"
            onClick={async () => {
              try {
                setLoading(true)
                await item.onClick(id, data)
                setSuccess(true)
              } catch (e) {
                setError(true)
              } finally {
                setLoading(false)
              }
            }}
          >
            {t('ui.deleteYes')}
          </Button>
        )}

        {success && (
          <Button
            theme="primary"
            className="mb-2 mr-1"
            onClick={() => {
              closeModal()
            }}
          >
            {t('ui.close')}
          </Button>
        )}
      </div>
    </>
  )
}

AutomatedModalConfirmation.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default AutomatedModalConfirmation
