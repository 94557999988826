/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import ReactQuill from 'react-quill'
import i18n from 'i18next'
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Form,
  FormInput,
  FormGroup,
  FormRadio,
  FormCheckbox,
  FormSelect,
  Button,
  FormFeedback,
  FormTextarea,
} from 'shards-react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useHistory, useParams } from 'react-router-dom'

import AutomatedModalConfirmation from '../custom/AutomatedModalConfirmation'
import config from '../../../config'

const AutomatedUpdate = ({ model }) => {
  const { t } = useTranslation()
  const [data, setData] = useState({})
  const [validField, setValidField] = useState({})
  const [formSubmit, setFormSubmit] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [_, setLoading] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [__, setLoadingData] = useState(false)
  const [error, setError] = useState([])
  const [loadingExtra, setLoadingExtra] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [actionInModal, setActionInModal] = useState()

  const { id, subId } = useParams()

  // used to popupate select, radio, checkbox when data is from api
  const [populatedForm, setPopulatedForm] = useState([])
  const formFromModel = model.update.form || model.create.form

  const history = useHistory()

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '50px',
    },
  }

  const loadDataAsync = async (dataFromGraphQl) => {
    setLoading(true)
    let waitingLoad = 0
    const form = {
      ...populatedForm,
    }

    formFromModel.forEach(async (row) => {
      if (row.type === 'row') {
        row.items.forEach(async (field) => {
          if (field.model) {
            waitingLoad += 1
            let dataToPopulate
            if (config.CONNECTOR === 'graphql' && field.model.graphql) {
              dataToPopulate = field.model.populateForm(dataFromGraphQl)
            } else {
              dataToPopulate = await field.model.populateForm(i18n.language)
            }
            form[field.id] = dataToPopulate
            setPopulatedForm(form)
            waitingLoad -= 1
            if (waitingLoad === 0) {
              setLoading(false)
            }
          }
        })
      } else if (row.model) {
        waitingLoad += 1
        let dataToPopulate
        if (config.CONNECTOR === 'graphql' && model.graphql) {
          dataToPopulate = row.model.populateForm(dataFromGraphQl)
        } else {
          dataToPopulate = await row.model.populateForm(i18n.language)
        }
        form[row.id] = dataToPopulate
        setPopulatedForm(form)
        waitingLoad -= 1
        if (waitingLoad === 0) {
          setLoading(false)
        }
      }
    })
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const submitModel = () => {
    let loading = 0
    const err = [...error]
    formFromModel.forEach(async (row) => {
      if (row.type === 'row') {
        row.items.forEach(async (field) => {
          if (field.needToSubmit) {
            try {
              loading += 1
              setLoadingExtra(loading)
              await field.needToSubmit(id, data[field.id], subId)
            } catch (e) {
              console.log(e)
              err.push(`ui.custom.${field.id}`)
              setError(err)
            } finally {
              loading -= 1
              setLoadingExtra(loading)
            }
          }
        })
      } else if (row.needToSubmit) {
        try {
          loading += 1
          setLoadingExtra(loading)
          await row.needToSubmit(id, data[row.id], subId)
        } catch (e) {
          console.log(e)
          err.push(`ui.custom.${row.id}`)
          setError(err)
        } finally {
          loading -= 1
          setLoadingExtra(loading)
        }
      }
    })
  }

  useEffect(() => {
    if (formSubmit && loadingExtra === 0) {
      history.push({
        pathname:
          model.url && typeof model.url === 'function'
            ? model.url(id)
            : model.url,
        state: { update: true },
      })
    }
  }, [loadingExtra])

  const populateFormAfterApi = (item) => {
    const tData = {
      ...data,
    }

    formFromModel.forEach(async (row) => {
      if (row.type === 'row') {
        row.items.forEach((field) => {
          if (field.id && item[field.id]) {
            tData[field.id] = item[field.id]
            setData(tData)
          }
        })
      } else if (row.id && item[row.id]) {
        tData[row.id] = item[row.id]
        setData(tData)
      }
    })
    setLoading(true)
  }

  if (config.CONNECTOR === 'graphql' && model.graphql) {
    const [
      submitGraphQl,
      { loading: mutationLoading, error: mutationError },
    ] = useMutation(model.update.submitQuery, {
      variables: !model.graphql ? {} : model.update.submit(id, data, subId),
      onCompleted: () => {
        submitModel()
      },
    })
  }

  const [fetchGrahQlItem] = useLazyQuery(model.update.query, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: !model.graphql
      ? {}
      : {
          id,
        },
    onCompleted: (itemToUpdate) => {
      populateFormAfterApi(model.update.transformQuery(itemToUpdate))
      loadDataAsync(itemToUpdate)
    },
  })

  const loadItemAsync = async () => {
    setLoadingData(true)
    if (config.CONNECTOR === 'graphql' && model.graphql) {
      fetchGrahQlItem()
    } else {
      const item = await model.update.getItem(id, i18n.language, subId)
      populateFormAfterApi(item)
    }
  }

  useEffect(() => {
    if (config.CONNECTOR !== 'graphql') {
      loadDataAsync()
    }
    loadItemAsync()
  }, [])

  const isValidField = (field, value) => {
    if (!field.id) {
      return true
    }

    let valid = true
    if (field.isValid) {
      valid = field.isValid(data)
    } else if (field.required) {
      if (field.type === 'select') {
        valid = value !== field.defaultValue && !!value
      } else if (value === '') {
        valid = false
      } else {
        valid = !!value

        if (field.regex && valid) {
          valid = field.regex.test(value)
        }
      }
    }
    if (
      field.id === 'start_visibility_date' &&
      data.end_visibility_date &&
      data.start_visibility_date &&
      new Date(data.end_visibility_date) < new Date(data.start_visibility_date)
    ) {
      valid = false
    }
    return valid
  }

  const onChangeCustom = (field, value) => {
    const tData = {
      ...data,
      [field]: value,
    }
    setData(tData)
  }

  const submitForm = async (event) => {
    console.log(data)
    event.preventDefault()
    setFormSubmit(true)

    let couldSendForm = true
    const tvalidField = {
      ...validField,
    }

    formFromModel.forEach((row) => {
      if (row.type === 'row') {
        row.items.forEach((field) => {
          tvalidField[field.id] = isValidField(field, data[field.id])
          if (!tvalidField[field.id]) {
            couldSendForm = false
          }
        })
      } else {
        tvalidField[row.id] = isValidField(row, data[row.id])
        if (!tvalidField[row.id]) {
          couldSendForm = false
        }
      }
    })

    setValidField(tvalidField)

    if (couldSendForm) {
      if (config.CONNECTOR === 'graphql') {
        submitGraphQl()
      } else {
        await model.update.submit(id, data, i18n.language, history, subId)
      }

      let loading = 0
      let noExtra = true
      const err = [...error]
      formFromModel.forEach(async (row) => {
        if (row.type === 'row') {
          row.items.forEach(async (field) => {
            if (field.needToSubmit) {
              noExtra = false
              try {
                loading += 1
                setLoadingExtra(loading)
                await field.needToSubmit(id, data[field.id], subId)
              } catch (e) {
                console.log(e)
                err.push(`ui.custom.${field.id}`)
                setError(err)
              } finally {
                loading -= 1
                setLoadingExtra(loading)
              }
            }
          })
        } else if (row.needToSubmit) {
          noExtra = false
          try {
            loading += 1
            setLoadingExtra(loading)
            await row.needToSubmit(id, data[row.id], subId)
          } catch (e) {
            console.log(e)
            err.push(`ui.custom.${row.id}`)
            setError(err)
          } finally {
            loading -= 1
            setLoadingExtra(loading)
          }
        }

        if (noExtra) {
          history.push({
            pathname:
              model.url && typeof model.url === 'function'
                ? model.url(id)
                : model.url,
            state: { update: true },
          })
        }
      })
    }
  }

  const makeMyInput = (field) => {
    switch (field.type) {
      case 'select':
        return (
          <>
            <label htmlFor={`fe${field.id}`}>
              {t(field.label)}
              {field.required && <span>*</span>}
            </label>
            <FormSelect
              id={`fe${field.id}`}
              value={data[field.id]}
              valid={!!validField[field.id] && field.showOk && formSubmit}
              invalid={!validField[field.id] && formSubmit}
              onChange={(event) => {
                const tData = {
                  ...data,
                  [field.id]: event.target.value,
                }
                setData(tData)
                const tvalidField = {
                  ...validField,
                  [field.id]: isValidField(field, event.target.value),
                }
                setValidField(tvalidField)
              }}
            >
              <option>{t(field.defaultValue)}</option>
              {field.data &&
                field.data.map((option, index) => (
                  <option value={option.value} key={`option${index}`}>
                    {option.label}
                  </option>
                ))}
              {populatedForm[field.id] &&
                populatedForm[field.id].map((option, index) => (
                  <option value={option.value} key={`option${index}`}>
                    {option.label}
                  </option>
                ))}
            </FormSelect>
            <FormFeedback valid>{t('form.fieldValid')}</FormFeedback>
            <FormFeedback invalid>{t('form.fieldInvalid')}</FormFeedback>
          </>
        )
      case 'wysiwyg':
        return (
          <>
            <label htmlFor={`fe${field.id}`}>
              {t(field.label)}
              {field.required && <span>*</span>}
            </label>
            <ReactQuill
              id={`fe${field.id}`}
              value={data[field.id] ? data[field.id] : ''}
              className="add-new-post__editor mb-1"
              onChange={(value) => {
                const tData = {
                  ...data,
                  [field.id]: value,
                }
                setData(tData)
              }}
            />
          </>
        )
      case 'textarea':
        return (
          <>
            <label htmlFor={`fe${field.id}`}>
              {t(field.label)}
              {field.required && <span>*</span>}
            </label>
            <FormTextarea
              id={`fe${field.id}`}
              valid={!!validField[field.id] && field.showOk && formSubmit}
              invalid={!validField[field.id] && formSubmit}
              value={data[field.id]}
              rows="5"
              onChange={(event) => {
                const tData = {
                  ...data,
                  [field.id]: event.target.value,
                }
                setData(tData)

                const tvalidField = {
                  ...validField,
                  [field.id]: isValidField(field, event.target.value),
                }
                setValidField(tvalidField)
              }}
            />
            <FormFeedback invalid>{t('form.fieldInvalid')}</FormFeedback>
          </>
        )
      case 'checkbox':
        return (
          <>
            <FormCheckbox
              checked={data[field.id]}
              value={data[field.id]}
              valid={!!validField[field.id] && field.showOk && formSubmit}
              invalid={!validField[field.id] && formSubmit}
              onChange={() => {
                const tData = {
                  ...data,
                  [field.id]: !data[field.id],
                }
                setData(tData)

                const tvalidField = {
                  ...validField,
                  [field.id]: isValidField(field, !data[field.id]),
                }
                setValidField(tvalidField)
              }}
            >
              {field.label && <>{t(field.label)}</>}
              {field.labelWithLink && (
                <span>
                  {field.labelWithLink.map((label, index) => (
                    <span key={`label${index}`}>
                      {label.link ? (
                        <a
                          href={label.link}
                          target={label.target}
                          rel="noopener noreferrer"
                        >
                          {t(label.text)}
                        </a>
                      ) : (
                        <>{t(label.text)}</>
                      )}
                    </span>
                  ))}
                </span>
              )}
            </FormCheckbox>
            <FormFeedback valid>{t('form.fieldValid')}</FormFeedback>
            <FormFeedback invalid>{t('form.fieldInvalid')}</FormFeedback>
          </>
        )
      case 'checkboxes':
        return (
          <>
            <label htmlFor={`fe${field.id}`}>{t(field.label)}</label>
            {!!validField[field.id] && formSubmit && (
              <FormFeedback
                invalid
                style={{
                  display: 'block',
                  marginBottom: '10px',
                  marginTop: '-5px',
                }}
              >
                {t('form.fieldInvalid')}
              </FormFeedback>
            )}
            {field.data &&
              field.data.map((box, index) => (
                <span key={`multi-checkbox${field.id}-${index}`}>
                  {makeMyInput({
                    type: 'multi-checkbox',
                    id: field.id,
                    label: box.label,
                    value: box.value,
                  })}
                </span>
              ))}
            {populatedForm[field.id] &&
              populatedForm[field.id].map((box, index) => (
                <span key={`multi-checkbox${field.id}-${index}`}>
                  {makeMyInput({
                    type: 'multi-checkbox',
                    id: field.id,
                    label: box.label,
                    value: box.value,
                  })}
                </span>
              ))}
          </>
        )
      case 'multi-checkbox':
        return (
          <FormCheckbox
            checked={
              data[field.id] &&
              data[field.id].indexOf(parseInt(field.value, 10)) !== -1
            }
            onChange={() => {
              const validatedFields = data[field.id] || []
              const pos = validatedFields.indexOf(parseInt(field.value, 10))
              if (pos === -1) {
                validatedFields.push(parseInt(field.value, 10))
              } else {
                validatedFields.splice(pos, 1)
              }

              const tData = {
                ...data,
                [field.id]: validatedFields,
              }
              setData(tData)
            }}
          >
            {field.label && <>{t(field.label)}</>}
          </FormCheckbox>
        )
      case 'radios':
        return (
          <>
            <label htmlFor={`fe${field.id}`}>{t(field.label)}</label>
            {!!validField[field.id] && formSubmit && (
              <FormFeedback
                invalid
                style={{
                  display: 'block',
                  marginBottom: '10px',
                  marginTop: '-5px',
                }}
              >
                {t('form.fieldInvalid')}
              </FormFeedback>
            )}
            {field.data &&
              field.data.map((box, index) => (
                <span key={`radio${field.id}-${index}`}>
                  {makeMyInput({
                    type: 'radio',
                    id: field.id,
                    label: box.label,
                    value: box.value,
                  })}
                </span>
              ))}
            {populatedForm[field.id] &&
              populatedForm[field.id].map((box, index) => (
                <span key={`radio${field.id}-${index}`}>
                  {makeMyInput({
                    type: 'radio',
                    id: field.id,
                    label: box.label,
                    value: box.value,
                  })}
                </span>
              ))}
          </>
        )
      case 'radio':
        return (
          <FormRadio
            checked={data[field.id] === field.value}
            onChange={() => {
              const tData = {
                ...data,
                [field.id]: field.value,
              }
              setData(tData)
            }}
          >
            {field.label && <>{t(field.label)}</>}
          </FormRadio>
        )
      case 'submit':
        return (
          <>
            <Button
              type="submit"
              className={field.className}
              onClick={submitForm}
            >
              {t(field.label)}
            </Button>
            {config.CONNECTOR === 'graphql' &&
              model.graphql &&
              (mutationLoading || loadingExtra > 0) && (
                <p>{t('ui.mutationLoading')}</p>
              )}
            {config.CONNECTOR === 'graphql' &&
              model.graphql &&
              mutationError && <p>{t('ui.mutationErrorUpdate')}</p>}
            {error &&
              error.length > 0 &&
              error.map((err, index) => <p key={index}>{t(err)}</p>)}
          </>
        )
      case 'button':
        return (
          <Button
            type="button"
            className={field.className}
            onClick={() => {
              if (field.history) {
                history.push(field.history)
              } else if (field.withConfirmation) {
                setIsOpen(true)
                setActionInModal(field)
              } else {
                field.onClick(id)
              }
            }}
          >
            {t(field.label)}
          </Button>
        )
      case 'custom':
        return (
          <>
            <label htmlFor={`fe${field.id}`}>
              {t(field.label)}
              {field.required && <span>*</span>}
            </label>
            {field.component({
              model,
              id,
              data,
              onChangeCustom,
              field,
            })}
          </>
        )
      case 'back':
        return (
          <Button
            type="button"
            theme="secondary"
            className={field.className}
            onClick={() => {
              history.push(
                model.url && typeof model.url === 'function'
                  ? model.url(id)
                  : model.url,
              )
            }}
          >
            {t(field.label)}
          </Button>
        )
      default:
        return (
          <>
            <label htmlFor={`fe${field.id}`}>
              {t(field.label)}
              {field.required && <span>*</span>}
            </label>
            <FormInput
              id={`fe${field.id}`}
              type={field.type}
              value={data[field.id]}
              valid={!!validField[field.id] && field.showOk && formSubmit}
              invalid={!validField[field.id] && formSubmit}
              placeholder={t(field.placeholder)}
              max={
                field.id === 'start_visibility_date' &&
                data.end_visibility_date &&
                data.end_visibility_date
              }
              min={
                field.id === 'end_visibility_date' &&
                data.start_visibility_date &&
                data.start_visibility_date
              }
              onChange={(event) => {
                const tData = {
                  ...data,
                  [field.id]: event.target.value,
                }
                setData(tData)

                const tvalidField = {
                  ...validField,
                  [field.id]: isValidField(field, event.target.value),
                }
                setValidField(tvalidField)
              }}
            />
            <FormFeedback valid>{t('form.fieldValid')}</FormFeedback>
            <FormFeedback invalid>
              {t('form.fieldInvalid')}
              {field.id === 'start_visibility_date' &&
                data.end_visibility_date &&
                data.end_visibility_date &&
                new Date(data.end_visibility_date).getTime() <
                  new Date(data.start_visibility_date).getTime() && (
                  <>
                    <br />
                    {t('dateError.errorInferior')}
                  </>
                )}
            </FormFeedback>
          </>
        )
    }
  }

  return (
    <>
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">{t(model.update.title)}</h6>
            </CardHeader>
            <CardBody className="p-0 pb-3">
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form>
                        {formFromModel.map((item, indexRow) => (
                          <div key={`row${indexRow}`}>
                            {item.type === 'row' ? (
                              <Row form>
                                {item.items.map((field, index) => (
                                  <Col
                                    md={field.md}
                                    className="form-group"
                                    key={`col${index}`}
                                  >
                                    {makeMyInput(field)}
                                  </Col>
                                ))}
                              </Row>
                            ) : (
                              <FormGroup>{makeMyInput(item)}</FormGroup>
                            )}
                          </div>
                        ))}
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {isOpen && (
        <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles}>
          <AutomatedModalConfirmation
            {...{ item: actionInModal, id, data }}
            closeModal={closeModal}
          />
        </Modal>
      )}
    </>
  )
}

AutomatedUpdate.propTypes = {
  model: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default AutomatedUpdate
