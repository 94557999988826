import React, { useState, useEffect } from 'react'
import {
  Row,
  Col,
  Button,
} from 'shards-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams, useLocation } from 'react-router-dom'

import PageTitle from '../common/PageTitle'

import { List } from './form'

const AutomatedList = ({ model, url }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { id } = useParams()
  const { pathname } = useLocation()
  const [parentTitle, setParentTitle] = useState('')

  useEffect(() => {
    if (id > 0 && model.parentModel) {
      model.parentModel(id).then((parent) => {
        setParentTitle(parent)
      })
    }
  }, [id])

  return (
    <>
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="9" title={`${t(model.title)}${parentTitle}`} subtitle={t(model.subtitle)} className="text-sm-left" />

        {model.create && (
          <Col className="text-right">
            <Button
              theme="primary"
              className="mb-2 mr-1"
              onClick={() => {
                history.push(`${pathname}/create`)
              }}
            >
              {t('ui.add')}
            </Button>
          </Col>
        )}
      </Row>

      <List {...{ model, url, active: true }} />
    </>
  )
}

AutomatedList.propTypes = {
  model: PropTypes.objectOf(PropTypes.any).isRequired,
  url: PropTypes.string.isRequired,
}

export default AutomatedList
