import React from 'react'
import {
  Button,
} from 'shards-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const AutomatedModalBatchDelete = ({ closeModal, batchIds, items, batchAction, model }) => {
  const { t } = useTranslation()
  const usersToDelete = items.filter(user => batchIds.indexOf(user.id) !== -1)
  return (
    <>
      <p style={{ textAlign: 'center' }}>
        {t(batchAction.title)}
        {usersToDelete?.map(user => (
          <div key={user.id}>
            <strong style={{ fontSize: '1.2rem' }}>
              {model.delete && model.delete.getTitle(user)}
              {!model.delete && model.list.batchActions && model.list.batchActions.getTitle(user)}
            </strong>
          </div>
        ))}
      </p>
      <div style={{ textAlign: 'center' }}>
        <Button
          theme="danger"
          className="mb-2 mr-1"
          onClick={() => {
            closeModal()
          }}
        >
          {t('ui.deleteNo')}
        </Button>

        <Button
          theme="primary"
          className="mb-2 mr-1"
          onClick={async () => {
            await batchAction.submit(batchIds)
            if (model.delete) {
              closeModal(true)
            } else {
              closeModal()
            }
          }}
        >
          {t('ui.deleteYes')}
        </Button>
      </div>
    </>
  )
}

AutomatedModalBatchDelete.propTypes = {
  batchAction: PropTypes.objectOf(PropTypes.any).isRequired,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  batchIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  closeModal: PropTypes.func.isRequired,
  model: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default AutomatedModalBatchDelete
