import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  FormGroup,
} from 'shards-react'
import { useParams } from 'react-router-dom'

const AutomatedUpdate = ({ model, id }) => {
  const { t } = useTranslation()
  const [data, setData] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [_, setLoading] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [__, setLoadingData] = useState(false)

  // used to popupate select, radio, checkbox when data is from api
  const [populatedForm, setPopulatedForm] = useState([])

  const { subId } = useParams()

  const formFromModel = model.read.form || model.create.form

  const loadDataAsync = async () => {
    setLoading(true)
    let waitingLoad = 0
    const form = {
      ...populatedForm,
    }

    formFromModel.forEach(async (row) => {
      if (row.type === 'row') {
        row.items.forEach(async (field) => {
          if (field.model) {
            waitingLoad += 1
            const dataToPopulate = await field.model.populateForm()
            form[field.id] = dataToPopulate
            setPopulatedForm(form)
            waitingLoad -= 1
            if (waitingLoad === 0) {
              setLoading(false)
            }
          }
        })
      } else if (row.model) {
        waitingLoad += 1
        const dataToPopulate = await row.model.populateForm()
        form[row.id] = dataToPopulate
        setPopulatedForm(form)
        waitingLoad -= 1
        if (waitingLoad === 0) {
          setLoading(false)
        }
      }
    })
  }

  const loadItemAsync = async () => {
    setLoadingData(true)
    const item = await model.read.getItem(id, i18n.language, subId)
    const tData = {
      ...data,
    }

    formFromModel.forEach(async (row) => {
      if (row.type === 'row') {
        row.items.forEach((field) => {
          if (field.id && item[field.id]) {
            tData[field.id] = item[field.id]
            setData(tData)
          }
        })
      } else if (row.id && item[row.id]) {
        tData[row.id] = item[row.id]
        setData(tData)
      }
    })
    setLoadingData(false)
  }

  useEffect(() => {
    loadDataAsync()
    loadItemAsync()
  }, [])

  const makeMyInput = (field) => {
    switch (field.type) {
      case 'select':
        return (
          <>
            <label htmlFor={`fe${field.id}`}>{t(field.label)}</label>
            {field.data && field.data.map((option, index) => {
              if (option.value === data[field.id]) {
                return (<p key={`option${index}`}>{option.label}</p>)
              }
              return (<span key={`option${index}`} />)
            })}
            {populatedForm[field.id] && populatedForm[field.id].map((option, index) => {
              if (option.value === data[field.id]) {
                return (<p key={`option${index}`}>{option.label}</p>)
              }
              return (<span key={`option${index}`} />)
            })}
          </>
        )
      case 'checkbox':
        return (
          <>
            {field.label && (<>{t(field.label)}</>)}
            {field.labelWithLink && (
            <span>
              {field.labelWithLink.map((label, index) => (
                <span key={`label${index}`}>
                  {label.link ? (
                    <a href={label.link} target={label.target} rel="noopener noreferrer">{t(label.text)}</a>
                  ) : (
                    <>{t(label.text)}</>
                  )}
                </span>
              ))}
            </span>
            )}
            {' : '}
            {data[field.id] ? t('ui.radioYes') : t('ui.radioNo')}
          </>
        )
      case 'checkboxes':
        return (
          <>
            <label htmlFor={`fe${field.id}`}>{t(field.label)}</label>
            {field.data && field.data.map((option, index) => {
              if (data[field.id] && data[field.id].indexOf(option.value) !== -1) {
                return (<p key={`check${field.id}-${index}`}>{option.label}</p>)
              }
              return (<span key={`check${field.id}-${index}`} />)
            })}
            {populatedForm[field.id] && populatedForm[field.id].map((option, index) => {
              if (data[field.id] && data[field.id].indexOf(option.value) !== -1) {
                return (<p key={`check${field.id}-${index}`}>{option.label}</p>)
              }
              return (<span key={`check${field.id}-${index}`} />)
            })}
          </>
        )
      case 'custom':
        return (
          <>
            <label htmlFor={`fe${field.id}`}>{t(field.label)}</label>
            {field.component({ items: data[field.id], id })}
          </>
        )
      case 'radios':
        return (
          <>
            <label htmlFor={`fe${field.id}`}>{t(field.label)}</label>
            {field.data && field.data.map((option, index) => {
              if (option.value === data[field.id]) {
                return (<p key={`radio${field.id}-${index}`}>{option.label}</p>)
              }
              return (<span key={`radio${field.id}-${index}`} />)
            })}
            {populatedForm[field.id] && populatedForm[field.id].map((option, index) => {
              if (option.value === data[field.id]) {
                return (<p key={`radio${field.id}-${index}`}>{option.label}</p>)
              }
              return (<span key={`radio${field.id}-${index}`} />)
            })}
          </>
        )
        case 'link':
        return (
          <>
            <label htmlFor={`fe${field.id}`}>{t(field.label)}</label>
            <p><a href={data[field.id]} rel="noreferrer" target="_blank">{data[field.id]}</a></p>
          </>
        )
      case 'wysiwyg':
        return (
          <>
            <label htmlFor={`fe${field.id}`}>{t(field.label)}</label>
            <div dangerouslySetInnerHTML={{ __html: data[field.id] }} />
          </>
        )
      case 'submit':
        return (<></>)
      default:
        return (
          <>
            <label htmlFor={`fe${field.id}`}>{t(field.label)}</label>
            <p>{data[field.id]}</p>
          </>
        )
    }
  }

  return (
    <Row>
      <Col>
        <Card small className="mb-4">
          {data && (
            <CardHeader className="border-bottom">
              <p>
                {model.url && typeof model.url === 'function' ? (
                  <a href={model.url(id)}>
                    &larr;
                    &nbsp;
                    {t('ui.back')}
                  </a>
                  ) : (
                    <a href={model.url}>
                      &larr;
                      &nbsp;
                      {t('ui.back')}
                    </a>
                  )}
              </p>
              <h6 className="m-0">
                {t(model.read.title)}
              </h6>
            </CardHeader>
          )}
          <CardBody className="p-0 pb-3">
            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Row>
                  <Col>
                    {formFromModel.map((item, indexRow) => (
                      <div key={`row${indexRow}`}>
                        {item.type === 'row' ? (
                          <Row form>
                            {item.items.map((field, index) => (
                              <Col md={field.md} className="form-group" key={`col${index}`}>
                                { makeMyInput(field) }
                              </Col>
                              ))}
                          </Row>
                          ) : (
                            <FormGroup>
                              { makeMyInput(item) }
                            </FormGroup>
                          )}
                      </div>
                      ))}
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

AutomatedUpdate.propTypes = {
  model: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.string.isRequired,
}

export default AutomatedUpdate
