import React, { useState } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormFeedback,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
  Button,
} from 'shards-react'
import jwtDecode from 'jwt-decode'
import {
  useHistory,
  useLocation,
} from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import api from '../api'
import logo from '../images/shards-dashboards-logo.svg'

const Login = () => {
  const isLocal = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
  const history = useHistory()
  const location = useLocation()
  const { state } = location
  const { t } = useTranslation()
  const [noAccess, setNoAccess] = useState(state ? state.noAccess : false)
  const [email, setEmail] = useState(isLocal ? 'admin@podcaststory.test' : '')
  const [password, setPassword] = useState(isLocal ? '12345' : '')
  const [error, setError] = useState(false)

  const onSubmit = async (event) => {
    event.preventDefault()
    setError(false)
    setNoAccess(false)
    if (email === '' || password === '') {
      setError(true)
      return
    }

    try {
      const user = await api.loginWithCredentials(email, password)
      if (user) {
        const usr = jwtDecode(user.access_token)
        if (usr.role === 'ps_admin') {
          localStorage.setItem('accessToken', user.access_token)
          localStorage.setItem('refreshToken', user.refresh_token)
          history.push('/')
        } else {
          setNoAccess(true)
        }
      }
    } catch (e) {
      console.log(e)
      setError(true)
    }
  }

  return (
    <Container fluid className="main-content-container col-lg-6">
      <Row
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Col>
          <Card small className="mb-4">
            <CardBody
              className="p-0 pb-3"
              style={{ margin: '50px' }}
            >
              <div
                className="d-table m-auto"
                style={{ paddingBottom: '50px' }}
              >
                <img
                  id="main-logo"
                  className="d-inline-block align-top mr-1"
                  style={{ maxWidth: '25px' }}
                  src={logo}
                  alt="Podcast Story"
                />
                <span className="d-none d-md-inline ml-1">
                  Podcast Story
                </span>
              </div>

              <Form>
                <FormGroup>
                  <InputGroup className="mb-3">
                    <InputGroupAddon type="prepend">
                      <InputGroupText>@</InputGroupText>
                    </InputGroupAddon>
                    <FormInput
                      value={email}
                      placeholder={t('login.username')}
                      onChange={(event) => {
                        setEmail(event.target.value)
                      }}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <FormInput
                    type="password"
                    placeholder={t('login.password')}
                    value={password}
                    onChange={(event) => {
                      setPassword(event.target.value)
                    }}
                  />
                </FormGroup>
                {error && (
                  <FormFeedback
                    invalid
                    style={{ display: 'block', marginBottom: '10px', marginTop: '-5px' }}
                  >
                    {t('form.fieldInvalid')}
                  </FormFeedback>
                )}
                {noAccess && (
                <FormFeedback
                  invalid
                  style={{ display: 'block', marginBottom: '10px', marginTop: '-5px' }}
                >
                  {t('form.fieldLoginNoAccess')}
                </FormFeedback>
              )}
                <FormGroup style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
                >
                  <Button
                    type="submit"
                    onClick={onSubmit}
                  >
                    {t('login.submit')}
                  </Button>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

Login.propTypes = {
  // model: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default Login
