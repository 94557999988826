import React from 'react'
import axios from 'axios'
import api from '../api'
import config from '../config'
import { dataURItoBlob } from '../lib'
import {
  AutomatedModalBatchDelete,
  Photos,
} from '../components/automated/custom'

import { SubCategories } from './SubCategories'

export const Categories = {
  title: 'categories.title',
  url: '/categories',
  populateForm: () => api.getCategories().then((categories) => {
    const cat = []
    if (categories) {
      categories.items.forEach((category) => {
        cat.push({ value: category.id, visible: category.visible, label: category.label })
        if (category.subcategories) {
          category.subcategories.forEach((subcategory) => {
            cat.push({ value: subcategory.id, visible: subcategory.visible, label: `-- ${subcategory.label}` })
          })
        }
      })
    }
    return cat
  }),
  list: {
    title: 'categories.list',
    orderable: (categories) => {
      categories.forEach((cat, index) => {
        api.putCategoryPos(cat.id, { pos: index * 10000 })

        if (categories.subcategories) {
          categories.subcategories((subCat, subIndex) => {
            api.putCategory(subCat.id, { pos: index * 10000 + subIndex })
          })
        }
      })
    },
    reorder: (items, index) => {
      const itms = items
      const catMoved = items[index]
      if (catMoved.subcategories) {
        catMoved.subcategories.reverse().forEach((subcategory) => {
          const idx = itms.findIndex((itm) => itm.id === subcategory.id)
          const [removed] = itms.splice(idx, 1)
          const parentIdx = itms.findIndex((it) => it.id === catMoved.id)
          itms.splice(parentIdx + 1, 0, removed)
        })
      }
      return itms
    },
    table: [
      {
        head: 'categories.label.id',
        key: 'id',
        width: '10%',
      },
      {
        head: 'categories.label.visible',
        key: 'visible',
        type: 'toggle',
        width: '10%',
        onChange: (cat) => api.putCategory(cat.id, { ...cat }),
      },
      {
        head: 'categories.label.title',
        key: 'label',
        width: '80%',
      },
    ],
    batchActions: {
      title: 'batchActions.label.title',
      defaultValue: 'batchActions.label.defaultValue',
      submitButton: 'batchActions.label.submit',
      items: [
        {
          label: 'batchActions.delete.label',
          title: 'batchActions.delete.title',
          // eslint-disable-next-line react/display-name
          component: (props) => (<AutomatedModalBatchDelete {...props} />),
          submit: (ids) => {
            console.log('confirm batch deletion with ids', ids)
          },
        },
      ],
      getTitle: (item) => `${item.label}`,
    },
    getItems: (limit, offset, lang, sortType, sortDirection, props) => {
      console.log('loading data for podcasts with params', limit, offset, lang, sortType, sortDirection, props)
      return api.getCategories(limit, offset).then((categories) => {
        const cat = []
        categories.items.forEach((category) => {
          cat.push({
            ...category,
          })
          if (category.subcategories) {
            category.subcategories.forEach((c) => {
              cat.push({
                ...c,
                label: `-- ${c.label}`,
                visible: c.visible,
                position: `${c.position}`,
              })
            })
          }
        })
        return cat
      })
    },
    limit: 20,
  },
  create: {
    title: 'categories.label.create',
    form: [
      {
        id: 'visible',
        type: 'checkbox',
        label: 'categories.label.visible',
      },
      {
        id: 'label',
        type: 'text',
        placeholder: 'categories.placeholder.title',
        label: 'categories.label.title',
        required: true,
      },
      {
        id: 'id_parent_category',
        type: 'select',
        defaultValue: 'ui.defaultValue',
        model: SubCategories,
        placeholder: 'categories.placeholder.category',
        label: 'categories.label.category',
      },
      {
        id: 'image',
        type: 'custom',
        // eslint-disable-next-line react/display-name
        component: (props) => (<Photos {...props} />),
        label: 'categories.label.image',
      },
      {
        type: 'row',
        items: [
          {
            type: 'back',
            label: 'ui.cancel',
            md: 1,
          },
          {
            type: 'submit',
            label: 'categories.label.submit',
            md: 2,
          },
        ],
      },
    ],
    submit: (data, lang) => {
      console.log('ready to create with', data, lang)
      return api.postCategory({
        label: data.label,
        id_parent_category: data.id_parent_category !== 'Choose...' ? data.id_parent_category : null,
      }).then((d) => {
        if (data && data.image && data.image.img && data.image.type) {
          const token = localStorage.getItem('accessToken')
          const url = `${config.API_BASE_URL}categories/${d.id}/cover`
          const blob = dataURItoBlob(data.image.img)
          return axios.post(
            url,
            blob,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': data.image.type,
              },
            },
          )
        }
      })
    },
  },
  update: {
    title: 'categories.label.update',
    getLink: (item) => item.id,
    getItem: (id) => api.getCategory(id).then((d) => ({
      ...d,
      id_parent_category: d.parent ? d.parent.id : null,
      visible: d.visible,
    })),
    // eslint-disable-next-line no-unused-vars
    photoUrl: (id, addBaseUrl = true, subId = null, flavour = 'default', format = '.jpg') => {
      const token = localStorage.getItem('accessToken')
      return `${addBaseUrl ? config.API_BASE_URL : ''}categories/${id}/cover${format}?authorization=${token}`
    },
    submit: (id, data, lang) => {
      console.log('ready to update', id, 'with', data, lang)
      return api.putCategory(id, {
        label: data.label,
        id_parent_category: data.id_parent_category,
        visible: data.visible,
        position: data.position,
      }).then(() => {
        if (data && data.image && data.image.img && data.image.type) {
          const token = localStorage.getItem('accessToken')
          const url = `${config.API_BASE_URL}categories/${id}/cover`
          const blob = dataURItoBlob(data.image.img)
          return axios.post(
            url,
            blob,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': data.image.type,
              },
            },
          )
        }
      })
    },
  },
  delete: {
    text: 'categories.delete.confirm',
    getTitle: (item) => `${item.label}`,
    submit: (item) => {
      const promise = new Promise((resolve) => {
        api.deleteCategory(item.id).then(() => {
          resolve(true)
        }, () => {
          resolve(false)
        })
      })
      return promise
    },
  },
}
