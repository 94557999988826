import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const PodcastEpisodeReadSources = ({ items }) => {
  const { t } = useTranslation()

  if (!items) {
    return (
      <p>{t('sources.noItem')}</p>
    )
  }

  return (
    <div className="category-container">
      <p style={{ marginLeft: '25px', marginBottom: 0 }}>
        {t('sources.title')}
        {' '}
        :
        {' '}
        {items.title}
      </p>
      <p style={{ marginLeft: '25px', marginBottom: 0 }}>
        {t('sources.description')}
        {' '}
        :
        {' '}
        {items.description}
      </p>
      <p style={{ marginLeft: '25px', marginBottom: 0 }}>
        {t('sources.publisher')}
        {' '}
        :
        {' '}
        {items.publisher}
      </p>
      <div>
        <p style={{ marginLeft: '25px', marginBottom: 0 }}>
          {t('sources.links')}
          {' '}
          :
        </p>
        {items?.merchant_urls?.map((merchant, index) => (
          <p style={{ marginLeft: '25px', marginBottom: 0 }} key={`merchant${index}`}><a href={merchant.value} target="_blank" rel="noreferrer">{merchant.value}</a></p>
          ))}
      </div>
    </div>
  )
}

PodcastEpisodeReadSources.propTypes = {
  items: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default PodcastEpisodeReadSources
