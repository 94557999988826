import React from 'react'
import PropTypes from 'prop-types'
import { Container } from 'shards-react'

const DefaultLayout = ({ children }) => (
  <Container fluid>
    {children}
  </Container>
)

DefaultLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default DefaultLayout
