import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  Row,
  // Col,
  // Card,
  // CardHeader,
  // CardBody,
  // ListGroup,
  // ListGroupItem,
  // Form,
  FormInput,
  // FormGroup,
  // FormRadio,
  // FormCheckbox,
  // FormSelect,
  Button,
  // FormFeedback,
  // FormTextarea,
} from 'shards-react'
import api from '../../../api'

const PodcastEpisodeReadSources = ({ onChangeCustom, field }) => {
  const { t } = useTranslation()
  const { subId, id } = useParams()
  const [source, setSource] = useState()
  const [newUrl, setNewUrl] = useState('')

  const addUrl = () => {
    if (newUrl !== '') {
      const urls = source ? source.merchant_urls : []
      const src = {
        ...source,
        merchant_urls: [
          ...urls || [],
          { id: newUrl, value: newUrl, action: 'new' },
        ],
      }
      delete src.deleted
      setSource(src)
      onChangeCustom(field.id, src)
      setNewUrl('')
    }
  }

  useEffect(() => {
    if (id && subId) {
      api.getPodcastEpisode(subId).then((e) => {
        setSource(e.source)
      })
    }
  }, [])

  return (
    <div
      className="source-container"
      style={{
      margin: '0 30px',
    }}
    >
      <Row style={{ marginBottom: '1rem' }}>
        <label htmlFor="source-title">
          {t('sources.label.title')}
          {/* <span>*</span> */}
        </label>
        <FormInput
          id="source-title"
          type="text"
          value={source ? source.title : ''}
          // valid={!!validField[field.id] && field.showOk && formSubmit}
          // invalid={!validField[field.id] && formSubmit}
          placeholder={t('sources.placeholder.title')}
          onChange={((event) => {
            const src = {
              ...source,
              title: event.target.value,
            }
            delete src.deleted
            setSource(src)
            onChangeCustom(field.id, src)
          })}
        />
      </Row>
      <Row style={{ marginBottom: '1rem' }}>
        <label htmlFor="source-description">
          {t('sources.label.description')}
          {/* <span>*</span> */}
        </label>
        <FormInput
          id="source-description"
          type="text"
          value={source ? source.description : ''}
          // valid={!!validField[field.id] && field.showOk && formSubmit}
          // invalid={!validField[field.id] && formSubmit}
          placeholder={t('sources.placeholder.description')}
          onChange={((event) => {
            const src = {
              ...source,
              description: event.target.value,
            }
            delete src.deleted
            setSource(src)
            onChangeCustom(field.id, src)
          })}
        />
      </Row>
      <Row style={{ marginBottom: '1rem' }}>
        <label htmlFor="source-publisher">
          {t('sources.label.publisher')}
          {/* <span>*</span> */}
        </label>
        <FormInput
          id="source-publisher"
          type="text"
          value={source ? source.publisher : ''}
          // valid={!!validField[field.id] && field.showOk && formSubmit}
          // invalid={!validField[field.id] && formSubmit}
          placeholder={t('sources.placeholder.publisher')}
          onChange={((event) => {
            const src = {
              ...source,
              publisher: event.target.value,
            }
            delete src.deleted
            setSource(src)
            onChangeCustom(field.id, src)
          })}
        />
      </Row>
      <div style={{
        marginLeft: '-15px',
        marginRight: '-15px',
      }}
      >
        <label htmlFor="source-merchant">
          {t('sources.label.merchant')}
          {/* <span>*</span> */}
        </label>
        <div style={{ marginTop: 10, display: 'flex' }}>
          <FormInput
            id="tag"
            type="text"
            style={{
            borderRadius: '.25rem 0 0 .25rem',
            borderRightWidth: 0,
          }}
            value={newUrl}
            placeholder={t('sources.placeholder.newUrl')}
            onKeyPress={(event) => {
            if (event.key === 'Enter' && newUrl !== '') {
              event.preventDefault()
              addUrl()
            }
          }}
            onChange={(event) => {
            setNewUrl(event.target.value)
          }}
          />
          <Button
            type="button"
            style={{
            width: '200px',
            borderRadius: '0 .25rem .25rem 0',
          }}
            onClick={addUrl}
          >
            {t('sources.label.newUrlSubmit')}
          </Button>
        </div>
        {source && source.merchant_urls && source.merchant_urls.map((mu) => (
          <>
            {['deleted', 'nothing'].indexOf(mu.action) === -1 && (
            <p key={`tag${mu.id}`} style={{ display: 'flex', marginTop: 5 }}>
              <a href={mu.value} target="_blank" rel="noopener norefer noreferrer">
                {mu.value}
              </a>
              <button
                type="button"
                style={{
                  backgroundColor: 'rgba(0, 123, 255, 0.5)',
                  borderRadius: '100px',
                  display: 'flex',
                  width: '20px',
                  height: '20px',
                  justifyContent: 'center',
                  border: '0 none',
                  padding: 0,
                  alignItems: 'center',
                  marginLeft: 10,
                }}
                onClick={() => {
                  const src = {
                    ...source,
                    merchant_urls: source.merchant_urls.map(m => {
                      if (m.id === mu.id) {
                        return {
                          ...m,
                          action: m.action !== 'new' ? 'deleted' : 'nothing',
                        }
                      }

                      return m
                    }),
                  }
                  setSource(src)
                  onChangeCustom(field.id, src)
                }}
              >
                x
              </button>
            </p>
          )}
          </>
        ))}
      </div>
      <Button
        type="button"
        style={{
            width: '200px',
            borderRadius: '0 .25rem .25rem 0',
            margin: '1rem 0 0 -10px',
          }}
        onClick={() => {
          const src = {
            title: '',
            description: '',
            publisher: '',
            deleted: true,
            merchant_urls: [],
          }
          setSource({ src, title: '', description: '', publisher: '' })
          onChangeCustom(field.id, src.id)
        }}
      >
        {t('sources.label.deleteSource')}
      </Button>
    </div>
  )
}

PodcastEpisodeReadSources.propTypes = {
  onChangeCustom: PropTypes.func,
  field: PropTypes.objectOf(PropTypes.any).isRequired,
}

PodcastEpisodeReadSources.defaultProps = {
  onChangeCustom: () => {},
}

export default PodcastEpisodeReadSources
