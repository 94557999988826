import React from 'react'
import { Nav } from 'shards-react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

import flagFR from '../../../images/flags/fr.png'
import flagEN from '../../../images/flags/en.png'

const styles = {
  button: {
    border: '0 none',
    background: 'none',
  },
  img: {
    width: 30,
    height: 30,
    borderRadius: 15,
  },
  imgActive: {
    border: '3px solid #007bff',
  },
}

const NavbarNav = () => {
  const { t } = useTranslation()
  return (
    <Nav navbar className="border-left flex-row">
      <button
        type="button"
        onClick={() => {
          i18n.changeLanguage('fr')
        }}
        style={styles.button}
      >
        <img
          src={flagFR}
          alt={t('lang.fr')}
          style={{ ...styles.img, ...(i18n.language === 'fr' ? styles.imgActive : {}) }}
        />
      </button>
      <button
        type="button"
        onClick={() => {
          i18n.changeLanguage('en')
        }}
        style={styles.button}
      >
        <img
          src={flagEN}
          alt={t('lang.en')}
          style={{ ...styles.img, ...(i18n.language === 'en' ? styles.imgActive : {}) }}
        />
      </button>
    </Nav>
  )
}

export default NavbarNav
