/* eslint-disable react/display-name */
import React from 'react'
import api from '../api'
import AutomatedModalBatchDelete from '../components/automated/custom/AutomatedModalBatchDelete'

const populateForm = async (limit = 20, offset = 0) => {
  let cat = []
  const contributors = await api.getContributors(limit, offset)
  contributors.items.forEach((contributor) => {
    cat.push({ value: contributor.id, label: contributor.name })
  })

  if (contributors.items.length === limit) {
    const next = await populateForm(limit, offset + limit)
    cat = [
      ...cat,
      ...next,
    ]
  }
  return cat
}

export const Contributors = {
  populateForm: () => populateForm(),
  url: '/contributors',
  list: {
    title: 'contributors.list',
    table: [
      {
        head: 'contributors.label.id',
        key: 'id',
        width: '10%',
      },
      {
        head: 'contributors.label.name',
        key: 'name',
        width: '90%',
      },
    ],
    batchActions: {
      title: 'batchActions.label.title',
      defaultValue: 'batchActions.label.defaultValue',
      submitButton: 'batchActions.label.submit',
      items: [
        {
          label: 'batchActions.delete.label',
          title: 'batchActions.delete.title',
          // eslint-disable-next-line react/display-name
          component: (props) => (<AutomatedModalBatchDelete {...props} />),
          submit: (ids) => {
            console.log('confirm batch deletion with ids', ids)
          },
        },
      ],
      getTitle: (item) => item.name,
    },
    getItems: (limit, offset, lang, sortType, sortDirection, props) => {
      console.log('loading data for podcasts with params', limit, offset, lang, sortType, sortDirection, props)
        return api.getContributors(limit, offset).then((d) => d.items)
    },
    limit: 20,
  },
  create: {
    title: 'contributors.label.create',
    form: [
      {
        id: 'name',
        type: 'text',
        placeholder: 'contributors.placeholder.name',
        label: 'contributors.label.name',
        required: true,
      },
      {
        type: 'row',
        items: [
          {
            type: 'back',
            label: 'ui.cancel',
          },
          {
            type: 'submit',
            label: 'contributors.label.submit',
          },
        ],
      },
    ],
    submit: (data) => api.postContributor({
      name: data.name,
    }),
  },
  update: {
    title: 'podcasts.read.title',
    getLink: (item) => item.id,
    getItem: (id) => api.getContributor(id),
    submit: (id, data, lang) => {
      console.log('ready to update', id, 'with', data, lang)
      return api.putContributor(id, {
        name: data.name,
      })
    },
  },
  delete: {
    text: 'contributors.delete.confirm',
    getTitle: (item) => item.name,
    submit: (item) => {
      console.log('delete item', item)
      const promise = new Promise((resolve) => {
        api.deleteContributor(item.id).then(() => {
          resolve(true)
        }, () => {
          resolve(false)
        })
      })
      return promise
    },
  },
}
