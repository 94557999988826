import React from 'react'
import dayjs from 'dayjs'
import {
  Container,
  Row,
  Button,
  Card,
} from 'shards-react'
import { useTranslation } from 'react-i18next'
import PageTitle from '../components/common/PageTitle'
import api from '../api'

const UserDownloader = () => {
    const { t } = useTranslation()

    const arrayToCsv = (data) => data.join('\r\n')

    const downloadBlob = (content, filename, contentType) => {
      // Create a blob
      // eslint-disable-next-line no-undef
      const blob = new Blob([content], { type: contentType })
      const url = URL.createObjectURL(blob)

      // Create a link to download it
      const pom = document.createElement('a')
      pom.href = url
      pom.setAttribute('download', filename)
      pom.click()
    }

    const download = async () => {
      const { items } = await api.getUsers()
      const data = items.map((i) => [
        i.email,
        i.origin,
      ])
      downloadBlob(arrayToCsv(data), `export-${dayjs().format('YYYY-MM-DD')}.csv`, 'text/csv;charset=utf-8;')
    }

    return (
      <Container fluid className="main-content px-4">

        <Row noGutters className="page-header py-4">
          <PageTitle title={t('users.title')} className="text-sm-left mb-3" />
        </Row>
        <Card
          className="downloader"
          style={{
          height: '500px',
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
        }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#efeff4',
              borderRadius: '12px',
              padding: '25px',
              marginTop: '100px',
            }}
          >
            <span className="material-icons" style={{ fontSize: '150px' }}>
              cloud_download
            </span>
            <Button
              theme="primary"
              className="mb-2 mr-1"
              onClick={download}
            >
              {t('users.ui.downloadFile')}
            </Button>
            {t('users.ui.acceptedTypes')}
          </div>
        </Card>
      </Container>
    )
}

export default UserDownloader
