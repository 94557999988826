import React from 'react'
import api from '../api'
import { AutomatedModalBatchDelete, EditoSearch } from '../components/automated/custom'
import { Podcasts } from './Podcasts'

export const EditoHightlight = {
  title: 'editos.highlight.title',
  url: '/highlight',
  list: {
    title: 'podcasts.list',
    orderable: (podcasts) => api.postHighlights({
      podcasts: podcasts.map(podcast => podcast.id),
      clean: true,
    }),
    search: {
      // eslint-disable-next-line react/display-name
      component: (props) => (<EditoSearch {...props} />),
      placeholder: 'editos.search.placeholder',
      submitLabel: 'editos.search.submit',
      submit: (term) => api.searchPodcast(term).then((d) => d.items),
      addContent: (podcasts) => api.postHighlights({
        podcasts: podcasts.map(podcast => podcast.id),
        clean: true,
      }),
    },
    table: [
      ...Podcasts.list.table,
    ],
    batchActions: {
      title: 'batchActions.label.title',
      defaultValue: 'batchActions.label.defaultValue',
      submitButton: 'batchActions.label.submit',
      items: [
        {
          label: 'editos.batchActions.remove.label',
          title: 'editos.batchActions.remove.title',
          // eslint-disable-next-line react/display-name
          component: (props) => (<AutomatedModalBatchDelete {...props} />),
          submit: (ids) => {
            console.log('confirm batch deletion with ids', ids)
          },
        },
      ],
      getTitle: (item) => item.title,
    },
    getItems: (limit, offset, lang, sortType, sortDirection, props) => {
      console.log('loading data for podcasts with params', limit, offset, lang, sortType, sortDirection, props)
        return api.getPodcastsHightlight(limit, offset).then((d) => d.items)
    },
    limit: 20,
  },
  read: {
    ...Podcasts.read,
  },
  update: {
    ...Podcasts.update,
  },
  delete: {
    text: 'editos.highlight.remove',
    getTitle: (item) => item.title,
    submit: (item) => {
      console.log('delete item', item)
      const promise = new Promise((resolve) => {
        api.deletePodcastFromHighlightList(item.id).then(() => {
          resolve(true)
        }, () => {
          resolve(false)
        })
      })
      return promise
    },
  },
}
