import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  Row,
} from 'shards-react'
import { useParams } from 'react-router-dom'

import { Update } from './form'

import PageTitle from '../common/PageTitle'

const AutomatedUpdate = ({ model }) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const [parentTitle, setParentTitle] = useState('')

  useEffect(() => {
    if (id > 0 && model.parentModel) {
      model.parentModel(id).then((parent) => {
        setParentTitle(parent)
      })
    }
  }, [id])

  return (
    <>
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="6" title={`${t(model.title)}${parentTitle}`} subtitle={t(model.subtitle)} className="text-sm-left" />
      </Row>

      <Update {...{ model, id }} />
    </>
  )
}

AutomatedUpdate.propTypes = {
  model: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default AutomatedUpdate
