const data = () => [
  // {
  //   title: 'navbar.homepage',
  //   htmlBefore: '<i class="material-icons">home</i>',
  //   to: '/homepage',
  // },
  {
    title: 'navbar.podcasts',
    htmlBefore: '<i class="material-icons">podcasts</i>',
    to: '/podcasts',
  },
  {
    title: 'navbar.categories',
    htmlBefore: '<i class="material-icons">category</i>',
    to: '/categories',
  },
  {
    title: 'navbar.edito.hightlight',
    htmlBefore: '<i class="material-icons">star</i>',
    to: '/highlight',
  },
  {
    title: 'navbar.users',
    htmlBefore: '<i class="material-icons">person</i>',
    to: '/users',
  },
  {
    title: 'navbar.contributors',
    htmlBefore: '<i class="material-icons">psychology</i>',
    to: '/contributors',
  },
]

export default data
