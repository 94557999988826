import React, { useEffect, useState, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import {
  Button,
} from 'shards-react'
import api from '../../../api'
import config from '../../../config'

const PodcastEpisodeReadAudio = ({ onChangeCustom, field }) => {
  const { subId, id } = useParams()
  const inputFile = useRef(null)
  const [audio, setAudio] = useState()
  const [playing, setPlaying] = useState(false)
  const token = localStorage.getItem('accessToken')
  const { t } = useTranslation()

  // eslint-disable-next-line no-unused-vars
  const [file, setFile] = useState()
  // eslint-disable-next-line no-unused-vars
  const [fileLoaded, setFileLoaded] = useState()

  const history = useHistory()
  history.listen(() => {
    if (audio) {
      audio.pause()
    }
  })

  useEffect(() => {
    api.getPodcastEpisodeAudio(id, subId, token).then((l) => {
      setAudio(new Audio(`${config.API_BASE_URL}${l}`))
    })
  }, [])

  useEffect(() => {
    if (audio) {
      if (playing) {
        audio.play()
      } else {
        audio.pause()
      }
    }
  },
  [playing])

  const loadFile = (e) => {
    const selectedFile = e.target.files[0]
    setFile(selectedFile)

    // eslint-disable-next-line no-undef
    const reader = new FileReader()
    reader.onload = (event) => {
      setFileLoaded(event.target.result)
      onChangeCustom(field.id, {
        audio: event.target.result,
        type: e.target.files[0].type,
      })
    }
    reader.readAsDataURL(selectedFile)
  }

  return (
    <div className="category-container">
      <Button
        theme="primary"
        className="mb-2 mr-1"
        onClick={() => {
          setPlaying(!playing)
        }}
      >
        {playing ? t('ui.pause') : t('ui.play')}
      </Button>
      <Button
        theme="primary"
        className="mb-2 mr-1"
        onClick={() => {
          inputFile.current.click()
        }}
      >
        {t('ui.updateAudio')}
      </Button>
      <input
        type="file"
        id="file"
        accept="audio/mp3"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={loadFile}
      />
    </div>
  )
}

PodcastEpisodeReadAudio.propTypes = {
  onChangeCustom: PropTypes.func,
  field: PropTypes.objectOf(PropTypes.any).isRequired,
}

PodcastEpisodeReadAudio.defaultProps = {
  onChangeCustom: () => {},
}

export default PodcastEpisodeReadAudio
