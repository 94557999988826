import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const LinkEpisodesList = ({ id }) => {
    const { t } = useTranslation()

    return (
      <p style={{ margin: 0 }}>
        <a href={`/podcasts/${id}/episodes`}>
          {t('ui.linkEpisodesList')}
        </a>
      </p>
    )
}

LinkEpisodesList.propTypes = {
  id: PropTypes.number.isRequired,
}

export default LinkEpisodesList
