import React from 'react'
import PropTypes from 'prop-types'
import { NavLink as RouteNavLink } from 'react-router-dom'
import { NavItem, NavLink } from 'shards-react'
import { useTranslation } from 'react-i18next'

const SidebarNavItem = ({ item }) => {
  const { t } = useTranslation()

  return (
    <NavItem>
      <NavLink tag={RouteNavLink} to={item.to}>
        {item.htmlBefore && (
          <div
            className="d-inline-block item-icon-wrapper"
            dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
          />
        )}
        {item.title && <span>{t(item.title)}</span>}
        {item.htmlAfter && (
          <div
            className="d-inline-block item-icon-wrapper"
            dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
          />
        )}
      </NavLink>
    </NavItem>
  )
}

SidebarNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default SidebarNavItem
