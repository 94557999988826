import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
} from 'shards-react'
import { useTranslation } from 'react-i18next'
import {
  useParams,
} from 'react-router-dom'
import api from '../../../api'

const Photos = ({ model, onChangeCustom, field, indications, wording, flavour }) => {
  const inputFile = useRef(null)
  const [hasImage, setHasImage] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [file, setFile] = useState()
  const [fileLoaded, setFileLoaded] = useState()
  const { t } = useTranslation()
  const { id, subId } = useParams()

  useEffect(async () => {
    try {
      if (id) {
        await api.checkImage(model.update.photoUrl(id, false, flavour, subId))
        setHasImage(true)
      }
    } catch (e) {
      console.log('no image found')
    }
  }, [])

  const loadFile = (e) => {
    const selectedFile = e.target.files[0]
    setFile(selectedFile)
    console.log(e.target.files[0])

    if (!selectedFile.type.startsWith('image/')) { return }

    const preview = document.getElementById('preview')

    const img = document.createElement('img')
    img.file = selectedFile
    preview.appendChild(img)

    // eslint-disable-next-line no-undef
    const reader = new FileReader()
    reader.onload = (event) => {
      setFileLoaded(event.target.result)
      onChangeCustom(field.id, {
        img: event.target.result,
        type: e.target.files[0].type,
      })
    }
    reader.readAsDataURL(selectedFile)
  }

  return (
    <div className="photos-container">
      {indications !== '' && <p fontSize="10px">{t(indications)}</p>}
      {hasImage && !fileLoaded && (
        <div className="photo">
          <img src={model.update.photoUrl(id, true, flavour, subId)} alt="" height="250" />
          <br />
          <Button
            theme="primary"
            className="mb-2 mr-1"
            onClick={() => {
              onChangeCustom(field.id, 'delete')
              setHasImage(false)
            }}
          >
            {t('ui.deleteImage')}
          </Button>
        </div>
      )}
      {fileLoaded && (
        <div className="photo">
          <img src={fileLoaded} alt="" height="250" />
          <br />
          <Button
            theme="primary"
            className="mb-2 mr-1"
            onClick={() => {
              setFileLoaded(null)
            }}
          >
            {t('ui.deleteImage')}
          </Button>
        </div>
      )}
      <Button
        theme="primary"
        className="mb-2 mr-1"
        onClick={() => {
          inputFile.current.click()
        }}
      >
        {wording ? t(wording) : t('ui.updateImage')}
      </Button>
      <input
        type="file"
        id="file"
        accept="image/png, image/jpeg"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={loadFile}
      />
      <div id="preview" />
    </div>
  )
}

Photos.propTypes = {
  model: PropTypes.objectOf(PropTypes.any).isRequired,
  onChangeCustom: PropTypes.func,
  field: PropTypes.objectOf(PropTypes.any).isRequired,
  flavour: PropTypes.oneOf(['default', 'alt', 'square']).isRequired,
  indications: PropTypes.string,
  wording: PropTypes.string,
}

Photos.defaultProps = {
  onChangeCustom: () => {},
  indications: '',
  wording: '',
}

export default Photos
