import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  FormInput,
  Button,
} from 'shards-react'
import api from '../../../api'

const PodcastFormTags = ({ id, onChangeCustom, field }) => {
  const { t } = useTranslation()
  const [tags, setTags] = useState([])
  const [newTag, setNewTag] = useState('')

  useEffect(() => {
    if (id) {
      api.getPodcastTags(id).then((ts) => {
        setTags(ts.items)
      })
    }
  }, [])

  useEffect(() => {
    onChangeCustom(field.id, tags)
  }, [tags])

  return (
    <div className="photos-container">
      {tags.map((tag) => (
        <button
          type="button"
          key={`tag${tag.id}`}
          style={{
            backgroundColor: 'transparent',
            border: '1px solid #007bff',
            borderRadius: '11px',
            padding: '0px 25px 0 10px',
            marginRight: '10px',
            position: 'relative',
            height: '22px',
          }}
          onClick={() => {
            const tgs = tags.filter(tg => tg.id !== tag.id)
            setTags(tgs)
          }}
        >
          {tag.name}
          <span style={{
            backgroundColor: 'rgba(0, 123, 255, 0.5)',
            borderRadius: '100px',
            display: 'flex',
            position: 'absolute',
            width: '20px',
            height: '20px',
            top: 0,
            right: 0,
            justifyContent: 'center',
          }}
          >
            x
          </span>
        </button>
      ))}
      <div style={{ marginTop: 10, display: 'flex' }}>
        <FormInput
          id="tag"
          type="text"
          style={{
            borderRadius: '.25rem 0 0 .25rem',
            borderRightWidth: 0,
          }}
          value={newTag}
          placeholder={t('podcasts.label.newTag')}
          onKeyPress={(event) => {
            if (event.key === 'Enter' && newTag !== '') {
              event.preventDefault()
              const tgs = [
                ...tags,
                { id: newTag, name: newTag },
              ]
              setTags(tgs)
              setNewTag('')
            }
          }}
          onChange={(event) => {
            setNewTag(event.target.value)
          }}
        />
        <Button
          type="button"
          style={{
            width: '200px',
            borderRadius: '0 .25rem .25rem 0',
          }}
          onClick={() => {
            if (newTag !== '') {
              const tgs = [
                ...tags,
                { id: newTag, name: newTag },
              ]
              setTags(tgs)
              setNewTag('')
            }
          }}
        >
          {t('podcasts.label.newTagSubmit')}
        </Button>
      </div>
    </div>
  )
}

PodcastFormTags.propTypes = {
  id: PropTypes.string,
  onChangeCustom: PropTypes.func,
  field: PropTypes.objectOf(PropTypes.any).isRequired,
}

PodcastFormTags.defaultProps = {
  onChangeCustom: () => {},
  id: '',
}

export default PodcastFormTags
