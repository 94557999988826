import React, { useState, useEffect } from 'react'
import { Nav } from 'shards-react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import SidebarNavItem from './SidebarNavItem'
import { Store } from '../../../flux'

const SidebarNavItems = () => {
  const [navItems, setNavItems] = useState(Store.getSidebarItems())
  const history = useHistory()
  const { t } = useTranslation()

  const onChange = () => {
    setNavItems(Store.getSidebarItems())
  }

  useEffect(() => {
    Store.addChangeListener(onChange)
  }, [])

  return (
    <div className="nav-wrapper">
      <Nav className="nav--no-borders flex-column">
        {navItems.map((item, idx) => (
          <SidebarNavItem key={idx} item={item} />
        ))}
        <li className="nav-item">
          <button
            type="button"
            className="nav-link"
            style={{
              background: 'transparent',
              border: '0 none',
              textAlign: 'left',
            }}
            onClick={() => {
              localStorage.clear()
              history.push('/login')
            }}
          >
            <div className="d-inline-block item-icon-wrapper">
              <i className="material-icons">logout</i>
            </div>
            <span>{t('navbar.logout')}</span>
          </button>
        </li>
      </Nav>
    </div>
  )
}

export default SidebarNavItems
