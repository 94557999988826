/* eslint-disable max-len */
import axios from 'axios'
import config from '../config'
import { isUserTokenExpired } from '../lib'

const api = axios.create({
  baseURL: config.API_BASE_URL,
})

api.defaults.headers.post['Content-Type'] = 'application/json'

const post = async (path, data = null, headers = {}) => {
  const res = await api({
    method: 'post',
    url: path,
    data,
    headers,
  })
  return res.data
}

const get = async (path, params = {}, cancelToken = null) => {
  const conf = {
    method: 'get',
    url: path,
    params,
  }

  if (cancelToken) {
    conf.cancelToken = cancelToken
  }

  const res = await api(conf)
  return res.data
}

const put = async (path, data, headers = {}) => {
  const res = await api({
    method: 'put',
    url: path,
    data,
    headers,
  })
  return res.data
}

const del = async (path, data) => {
  const res = await api({
    method: 'delete',
    url: path,
    data,
  })
  return res.data
}

const loginWithCredentials = async (login, password) => {
  const response = await post(config.LOGIN_URL, { login, password })
  return response
}

const getToken = async () => get('/token')

const checkImage = async (url) => get(url)

const forgotPwd = async (login) => post('/password/forgot', { login })

const getPodcasts = async (limit, offset, order, orderDir) => get('/podcasts', { limit, offset, order, order_dir: orderDir })
const getPodcast = async (idPodcast) => get(`/podcasts/${idPodcast}`)
const getPodcastEpisodes = async (idPodcast, limit, offset) => get(`/podcasts/${idPodcast}/episodes`, { limit, offset })
const getPodcastEpisode = async (idEpisode) => get(`/episodes/${idEpisode}`)
const getPodcastCover = async (idPodcast, flavour, format, token) => `podcasts/${idPodcast}/covers/${flavour}.${format}?authorization=${token}`
const getPodcastEpisodeCover = async (idPodcast, idEpisode, flavour, format, token) => `episodes/${idEpisode}/covers/${flavour}.${format}?authorization=${token}`
const getPodcastEpisodeAudio = async (idPodcast, idEpisode, token) => `episodes/${idEpisode}/content?authorization=${token}`
const getCategories = async (limit, offset) => get('/categories', { limit, offset })
const getCategory = async (id) => get(`/categories/${id}`)
const getContributors = async (limit, offset) => get('/contributors', { limit, offset })
const getContributor = async (id) => get(`/contributors/${id}`)
const getSources = async (limit, offset) => get('/sources', { limit, offset })
const getPodcastTeasers = async (id) => get(`/podcasts/${id}/teasers`)
const getPodcastTeaser = (idPodcast, idTeaser, token) => `podcasts/${idPodcast}/teasers/${idTeaser}/content?authorization=${token}`
const getPodcastTags = async (idPodcast) => get(`/podcasts/${idPodcast}/tags`)
const getPodcastsHightlight = async (limit, offset) => get('/podcasts/highlights', { limit, offset })
const getUsers = async () => get('/users')
const searchPodcast = async (q) => get('/search/podcasts', { q })

const createPodcast = async () => post('/podcasts')
const createCategory = async () => post('/categories')
const createContributor = async () => post('/contributors')
const createSource = async () => post('/sources')

const postPodcastTags = async (id, data) => post(`/podcasts/${id}/tags`, data)
const postPodcastCategories = async (id, data) => post(`/podcasts/${id}/categories`, data)
const postCategory = async (data) => post('/categories', data)
const postHighlights = async (data) => post('/podcasts/highlights', data)
const postSource = async (data) => post('/sources', data)
const postSourceUrl = async (data) => post('/merchant-urls', data)
const postPodcast = async (data) => post('/podcasts', data)
const postPodcastEpisode = async (id, data) => post(`/podcasts/${id}/episodes`, data)
const postContributor = async (data) => post('/contributors', data)

const putPodcast = async (idPodcast, data) => put(`/podcasts/${idPodcast}`, data)
const putCategory = async (id, data) => put(`/categories/${id}`, data)
const putEpisode = async (id, data) => put(`/episodes/${id}`, data)
const putSource = async (id, data) => put(`/sources/${id}`, data)
const putContributor = async (id, data) => put(`/contributors/${id}`, data)
const putCategoryPos = async (id, data) => put(`/categories/${id}/pos`, data)
const putPodcastPined = async (id) => put(`/podcasts/${id}/pin`)

const deletePodcast = async (idPodcast) => del(`/podcasts/${idPodcast}`)
const deletePodcastTeaser = async (idPodcast, idTeaser) => del(`/podcasts/${idPodcast}/teasers/${idTeaser}/content`)
const deletePodcastEpisode = async (idEpisode) => del(`/episodes/${idEpisode}`)
const deletePodcastFromHighlightList = async (idPodcast) => del(`/podcasts/${idPodcast}/highlight`)
const deleteCategory = async (idCat) => del(`/categories/${idCat}`)
const deleteSourceUrl = async (idSourceUrl) => del(`/merchant-urls/${idSourceUrl}`)
const deleteSource = async (idSource) => del(`/sources/${idSource}`)
const deleteContributor = async (idContributor) => del(`/contributors/${idContributor}`)
const deletePodcastPined = async (idPodcast) => del(`/podcasts/${idPodcast}/pin`)

const requestWithAuthorization = (request, token) => ({
  ...request,
  headers: {
    ...request.headers,
    Authorization: `bearer ${token}`,
  },
})

api.interceptors.request.use((cfg) => {
  const originalRequest = cfg
  const isLogin = cfg.url.indexOf('/token') !== -1

  const accessToken = localStorage.getItem('accessToken')
  const refreshToken = localStorage.getItem('refreshToken')
  if (accessToken && !isLogin) {
    if (isUserTokenExpired(accessToken)) {
      return getToken().then(
        (user) => {
          localStorage.setItem('accessToken', user.access_token)
          localStorage.setItem('refreshToken', user.refresh_token)
          return Promise.resolve(requestWithAuthorization(originalRequest, user.access_token))
        },
        () => Promise.reject(new Error('E_REFRESH_TOKEN_FAILED')),
      )
    }
  }
  let token = null
  if (accessToken) {
    token = (cfg.method === 'get' && isLogin) ? refreshToken : accessToken
  }
  if (token) {
    return requestWithAuthorization(cfg, token)
  }
  return cfg
}, (error) => Promise.reject(error))

export default {
  get,
  post,
  put,
  del,
  loginWithCredentials,
  getToken,
  checkImage,
  forgotPwd,
  getPodcasts,
  getPodcast,
  createPodcast,
  getCategories,
  createCategory,
  getContributors,
  createContributor,
  getSources,
  createSource,
  deletePodcast,
  getPodcastEpisodes,
  getPodcastEpisode,
  getPodcastEpisodeCover,
  getPodcastEpisodeAudio,
  getPodcastCover,
  getPodcastTeasers,
  getPodcastTeaser,
  putPodcast,
  getPodcastTags,
  postPodcastTags,
  postPodcastCategories,
  deletePodcastTeaser,
  deletePodcastEpisode,
  postCategory,
  deleteCategory,
  searchPodcast,
  getPodcastsHightlight,
  deletePodcastFromHighlightList,
  getCategory,
  putCategory,
  postHighlights,
  putEpisode,
  putSource,
  putPodcastPined,
  deletePodcastPined,
  postSource,
  deleteSourceUrl,
  postSourceUrl,
  deleteSource,
  postPodcast,
  postPodcastEpisode,
  postContributor,
  deleteContributor,
  getContributor,
  putContributor,
  putCategoryPos,
  getUsers,
}
