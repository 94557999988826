/* eslint-disable react/display-name */
import React from 'react'
import axios from 'axios'
import dayjs from 'dayjs'
import {
  AutomatedModalBatchDelete,
  PodcastEpisodeReadSources,
  PodcastEpisodeReadCover,
  PodcastEpisodeUpdateAudio,
  PodcastEpisodeUpdateSources,
  Photos,
} from '../components/automated/custom'
import { Contributors } from './Contributors'
import api from '../api'
import { dataURItoBlob } from '../lib'
import config from '../config'

export const Episodes = {
  title: 'episodes.title',
  parentModel: async (id) => {
    const podcast = await api.getPodcast(id)
    return podcast.title
  },
  parentUrl: '/podcasts',
  url: (id) => `/podcasts/${id}/episodes`,
  list: {
    title: 'episodes.list',
    table: [
      {
        head: 'episodes.label.num',
        key: 'num',
        width: '15%',
        textAlign: 'center',
      },
      {
        head: 'episodes.label.title',
        key: 'title',
        width: '40%',
      },
      {
        head: 'episodes.label.startPubDate',
        key: 'start_visibility_date',
        width: '17.5%',
        textAlign: 'center',
      },
      {
        head: 'episodes.label.endPubDate',
        key: 'end_visibility_date',
        width: '17.5%',
        textAlign: 'center',
      },
      {
        head: 'episodes.label.duration',
        key: 'duration',
        width: '10%',
        textAlign: 'center',
      },
    ],
    batchActions: {
      title: 'batchActions.label.title',
      defaultValue: 'batchActions.label.defaultValue',
      submitButton: 'batchActions.label.submit',
      items: [
        {
          label: 'batchActions.delete.label',
          title: 'batchActions.delete.title',
          // eslint-disable-next-line react/display-name
          component: (props) => (<AutomatedModalBatchDelete {...props} />),
          submit: (ids) => {
            console.log('confirm batch deletion with ids', ids)
          },
        },
      ],
      getTitle: (item) => item.title,
    },
    getItems: (limit, offset, lang, sortType, sortDirection, props) => {
      console.log('loading data for podcasts with params', limit, offset, lang, sortType, sortDirection, props)
        return api.getPodcastEpisodes(props.id, limit, offset).then((d) => {
          const formattedItems = []
          d.items.forEach((item) => {
            const startVDate = dayjs(item.start_visibility_date).format('DD-MM-YYYY à HH:mm')
            const endVDate = dayjs(item.end_visibility_date).format('DD-MM-YYYY à HH:mm')
            const formattedDates = {
              ...item,
              start_visibility_date: startVDate,
              end_visibility_date: endVDate,
            }
            formattedItems.push(formattedDates)
          })
          return formattedItems
        })
    },
    limit: 20,

  },
  create: {
    title: 'episodes.label.create',
    form: [
      {
        id: 'title',
        type: 'text',
        label: 'episodes.label.title',
        required: true,
      },
      {
        id: 'num',
        type: 'text',
        label: 'episodes.label.num',
        required: true,
      },
      {
        type: 'row',
        items: [
          {
            md: 3,
            id: 'start_visibility_date',
            type: 'date',
            label: 'episodes.label.startPubDate',
            required: true,
          },
          {
            md: 3,
            id: 'start_visibility_time',
            type: 'time',
            label: 'episodes.label.startPubTime',
            required: true,
          },
          {
            md: 3,
            id: 'end_visibility_date',
            type: 'date',
            label: 'episodes.label.endPubDate',
            required: true,
          },
          {
            md: 3,
            id: 'end_visibility_time',
            type: 'time',
            label: 'episodes.label.endPubTime',
            required: true,
          },
        ],
      },
      {
        id: 'description',
        type: 'text',
        placeholder: 'episodes.placeholder.description',
        label: 'episodes.label.description',
        required: true,
      },
      {
        id: 'author',
        type: 'select',
        defaultValue: 'ui.defaultValue',
        label: 'episodes.label.author',
        model: Contributors,
        required: true,
      },
      {
        id: 'narrator',
        type: 'select',
        defaultValue: 'ui.defaultValue',
        label: 'episodes.label.narrator',
        model: Contributors,
        required: true,
      },
      {
        id: 'guest',
        type: 'select',
        defaultValue: 'ui.defaultValue',
        label: 'episodes.label.guest',
        model: Contributors,
      },
      {
        id: 'stream_url',
        type: 'text',
        placeholder: 'episodes.placeholder.streamUrl',
        label: 'episodes.label.streamUrl',
      },
      {
        id: 'duration',
        type: 'text',
        placeholder: 'episodes.placeholder.duration',
        label: 'episodes.label.duration',
        md: 3,
        required: true,
      },
      {
        type: 'row',
        items: [
          {
            id: 'cover',
            type: 'custom',
            component: (props) => (<Photos {...props} flavour="default" />),
            label: 'episodes.label.cover',
          },
          {
            id: 'thumbnail',
            type: 'custom',
            component: (props) => (<Photos {...props} flavour="alt" />),
            label: 'episodes.label.thumbnail',
          },
        ],
      },
      {
        id: 'source',
        type: 'custom',
        // eslint-disable-next-line react/display-name
        component: (props) => (<PodcastEpisodeUpdateSources {...props} />),
        label: 'episodes.label.source',
        isValid: (data) => {
          if (!data.source) {
            return true
          }
          return !data.title || !data.description || !data.publisher
        },
      },
      {
        type: 'row',
        items: [
          {
            type: 'back',
            label: 'ui.cancel',
            md: 1,
          },
          {
            type: 'submit',
            label: 'episodes.label.create',
            md: 2,
          },
        ],
      },
    ],
    submit: (data, lang, history, params) => {
      console.log('ready to create with', data, lang, history, params)
      const { id } = params
      let promise
      const startDate = `${data.start_visibility_date}T${data.start_visibility_time}`
      const endDate = `${data.end_visibility_date}T${data.end_visibility_time}`
      console.log(startDate, endDate)
      if (startDate < endDate) {
        if (data.source && !data.source.deleted) {
          promise = api.postSource({
            title: data.source.title,
            description: data.source.description,
            publisher: data.source.publisher,
          })
        } else {
          promise = new Promise((resolve) => {
            resolve()
          })
        }

        return promise.then((source) => {
          let idSource
          if (source && source.id) {
            idSource = source.id
          }

          if (data.source?.merchant_urls) {
            data.source.merchant_urls.forEach((url) => {
              if (url.action === 'new') {
                api.postSourceUrl({
                  id_source: idSource,
                  url: url.value,
                })
              }
            })
          }

          const d = {
            num: data.num,
            title: data.title,
            description: data.description,
            duration: data.duration,
            stream_url: data.stream_url,
            id_author: data.author,
            id_narrator: data.narrator,
            id_guest: data.guest,
            id_source: idSource,
            start_visibility_date: dayjs(startDate).toISOString(),
            end_visibility_date: dayjs(endDate).toISOString(),
          }

            return api.postPodcastEpisode(id, d).then((episode) => {
              const subId = episode.id
              if (data && data.audio && data.audio.audio && data.audio.type) {
                const token = localStorage.getItem('accessToken')
                const url =
                  `${config.API_BASE_URL}episodes/${subId}/content`
                const blob = dataURItoBlob(data.audio.audio)
                axios.post(
                  url,
                  blob,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'Content-Type': data.audio.type,
                    },
                  },
                )
              }

              if (data && data.cover && data.cover.img && data.cover.type) {
                const token = localStorage.getItem('accessToken')
                const url =
                  `${config.API_BASE_URL}episodes/${subId}/covers/default`
                const blob = dataURItoBlob(data.cover.img)
                axios.post(
                  url,
                  blob,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'Content-Type': data.cover.type,
                    },
                  },
                )
              }
              if (data && data.thumbnail && data.thumbnail.img && data.thumbnail.type) {
                const token = localStorage.getItem('accessToken')
                const url =
                  `${config.API_BASE_URL}episodes/${subId}/covers/alt`
                const blob = dataURItoBlob(data.thumbnail.img)
                axios.post(
                  url,
                  blob,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'Content-Type': data.thumbnail.type,
                    },
                  },
                )
              }
            })
          })
        }
      },
    },
  read: {
    title: 'episodes.read.title',
    form: [
      {
        id: 'title',
        type: 'text',
        label: 'episodes.label.title',
      },
      {
        id: 'num',
        type: 'text',
        label: 'episodes.label.num',
      },
      {
        type: 'row',
        items: [
          {
            md: 4,
            id: 'start_visibility_date',
            type: 'date',
            label: 'episodes.label.startPubDate',
          },
          {
            md: 2,
            id: 'start_visibility_time',
            type: 'time',
            label: 'episodes.label.startPubTime',
          },
          {
            md: 4,
            id: 'end_visibility_date',
            type: 'date',
            label: 'episodes.label.endPubDate',
          },
          {
            md: 2,
            id: 'end_visibility_time',
            type: 'time',
            label: 'episodes.label.endPubTime',
          },
        ],
      },
      {
        id: 'description',
        type: 'text',
        label: 'episodes.label.description',
      },
      {
        id: 'author',
        type: 'text',
        label: 'episodes.label.author',
      },
      {
        id: 'narrator',
        type: 'text',
        label: 'episodes.label.narrator',
      },
      {
        id: 'stream_url',
        type: 'link',
        label: 'episodes.label.streamUrl',
        required: true,
      },
      {
        id: 'duration',
        type: 'text',
        placeholder: 'episodes.placeholder.duration',
        label: 'episodes.label.duration',
        md: 3,
      },
      {
        id: 'source',
        type: 'custom',
        component: (props) => (<PodcastEpisodeReadSources {...props} />),
        label: 'episodes.label.source',
      },
      {
        type: 'row',
        items: [
          {
            id: 'cover',
            type: 'custom',
            component: (props) => (<PodcastEpisodeReadCover {...props} flavour="default" />),
            label: 'episodes.label.cover',
            required: true,
            md: 6,
          },
          {
            id: 'thumbnail',
            type: 'custom',
            component: (props) => (<PodcastEpisodeReadCover {...props} flavour="alt" />),
            label: 'episodes.label.thumbnail',
            required: true,
          },
        ],
      },
    ],
    getLink: (item) => item.id,
    getItem: (idPodcast, lang, idEpisode) => api.getPodcastEpisode(idEpisode).then((d) => {
        const data = {
          ...d,
          author: d.author?.name,
          narrator: d.narrator?.name,
          items: d.items,
          start_visibility_date: dayjs(d.start_visibility_date).format('DD-MM-YYYY'),
          end_visibility_date: dayjs(d.end_visibility_date).format('DD-MM-YYYY'),
          start_visibility_time: dayjs(d.start_visibility_date).format('HH:mm'),
          end_visibility_time: dayjs(d.end_visibility_date).format('HH:mm'),
        }
        return data
      }),
  },
  update: {
    title: 'podcasts.update.title',
    getLink: (item) => item.id,
    form: [
      {
        id: 'title',
        type: 'text',
        label: 'episodes.label.title',
        required: true,
      },
      {
        id: 'num',
        type: 'text',
        label: 'episodes.label.num',
        required: true,
      },
      {
        type: 'row',
        items: [
          {
            id: 'start_visibility_date',
            type: 'date',
            label: 'episodes.label.startPubDate',
            required: true,
          },
          {
            md: 2,
            id: 'start_visibility_time',
            type: 'time',
            label: 'episodes.label.startPubTime',
            required: true,
          },
          {
            id: 'end_visibility_date',
            type: 'date',
            label: 'episodes.label.endPubDate',
            required: true,
          },
          {
            md: 2,
            id: 'end_visibility_time',
            type: 'time',
            label: 'episodes.label.endPubTime',
            required: true,
          },
        ],
      },
      {
        id: 'description',
        type: 'text',
        label: 'episodes.label.description',
        required: true,
      },
      {
        id: 'author',
        type: 'select',
        defaultValue: 'ui.defaultValue',
        label: 'episodes.label.author',
        model: Contributors,
        required: true,
      },
      {
        id: 'narrator',
        type: 'select',
        defaultValue: 'ui.defaultValue',
        label: 'episodes.label.narrator',
        model: Contributors,
        required: true,
      },
      {
        id: 'guest',
        type: 'select',
        defaultValue: 'ui.defaultValue',
        label: 'episodes.label.guest',
        model: Contributors,
      },
      {
        id: 'audio',
        type: 'custom',
        // eslint-disable-next-line react/display-name
        component: (props) => (<PodcastEpisodeUpdateAudio {...props} />),
        label: 'episodes.label.audio',
        needToSubmit: async (id, data, subId) => {
          if (data && data.audio && data.type) {
            const token = localStorage.getItem('accessToken')
            const url =
              `${config.API_BASE_URL}episodes/${subId}/content`
            const blob = dataURItoBlob(data.audio)
            return axios.post(
              url,
              blob,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': data.type,
                },
              },
            )
          }
        },
      },
      {
        id: 'stream_url',
        type: 'text',
        placeholder: 'episodes.placeholder.streamUrl',
        label: 'episodes.label.streamUrl',
      },
      {
        id: 'duration',
        type: 'text',
        placeholder: 'episodes.placeholder.duration',
        label: 'episodes.label.duration',
        md: 3,
        required: true,
      },
      {
        type: 'row',
        items: [
          {
            id: 'cover',
            type: 'custom',
            component: (props) => (<Photos {...props} flavour="default" />),
            label: 'episodes.label.cover',
            needToSubmit: async (id, data, subId) => {
              if (data && data.img && data.type) {
                const token = localStorage.getItem('accessToken')
                const url =
                  `${config.API_BASE_URL}episodes/${subId}/covers/default`
                const blob = dataURItoBlob(data.img)
                return axios.post(
                  url,
                  blob,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'Content-Type': data.type,
                    },
                  },
                )
              }
            },
          },
          {
            id: 'thumbnail',
            type: 'custom',
            component: (props) => (<Photos {...props} flavour="alt" />),
            label: 'episodes.label.thumbnail',
            needToSubmit: async (id, data, subId) => {
              if (data && data.img && data.type) {
                const token = localStorage.getItem('accessToken')
                const url =
                  `${config.API_BASE_URL}episodes/${subId}/covers/alt`
                const blob = dataURItoBlob(data.img)
                return axios.post(
                  url,
                  blob,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'Content-Type': data.type,
                    },
                  },
                )
              }
            },
          },
        ],
      },
      {
        id: 'source',
        type: 'custom',
        // eslint-disable-next-line react/display-name
        component: (props) => (<PodcastEpisodeUpdateSources {...props} />),
        label: 'episodes.label.source',
        isValid: (data) => {
          if (!data.source) {
            return true
          }
          return !data.title || !data.description || !data.publisher
        },
      },
      {
        type: 'row',
        items: [
          {
            type: 'back',
            label: 'ui.cancel',
            md: 1,
          },
          {
            type: 'submit',
            label: 'episodes.label.submit',
            md: 2,
          },
        ],
      },
    ],
    getItem: (id, lang, subId) => api.getPodcastEpisode(subId).then((d) => {
      const data = {
        ...d,
        author: d.author ? d.author.id : '',
        guest: d.guest ? d.guest.id : '',
        narrator: d.narrator ? d.narrator.id : '',
        start_visibility_date: dayjs(d.start_visibility_date).format('YYYY-MM-DD'),
        end_visibility_date: dayjs(d.end_visibility_date).format('YYYY-MM-DD'),
        start_visibility_time: dayjs(d.start_visibility_date).format('HH:mm'),
        end_visibility_time: dayjs(d.end_visibility_date).format('HH:mm'),
      }

      return data
    }),
    submit: (id, data, lang, history, subId) => {
      console.log('ready to update', id, 'with', data, lang, history, subId)

      let promise
      let idSource = data.source?.id

      if (data.source) {
        if (data.source.deleted && idSource) {
          promise = api.deleteSource(idSource)
        } else if (idSource) {
          promise = api.putSource(idSource, {
            title: data.source.title,
            description: data.source.description,
            publisher: data.source.publisher,
          })
        } else {
          promise = api.postSource({
            title: data.source.title,
            description: data.source.description,
            publisher: data.source.publisher,
          })
        }
      } else {
        promise = new Promise((resolve) => {
          resolve(true)
        })
      }

      return promise.then((source) => {
        if (source?.id) {
          idSource = source.id
        }

        if (data.source?.merchant_urls) {
          data.source.merchant_urls.forEach((url) => {
            if (url.action === 'deleted') {
              api.deleteSourceUrl(url.id)
            } else if (url.action === 'new') {
              api.postSourceUrl({
                id_source: idSource,
                url: url.value,
              })
            }
          })
        }
        const newStartDate = `${data.start_visibility_date}T${data.start_visibility_time}`
        const newEndDate = `${data.end_visibility_date}T${data.end_visibility_time}`
        const d = {
          num: data.num,
          title: data.title,
          description: data.description,
          duration: data.duration,
          stream_url: data.stream_url,
          id_author: data.author,
          id_narrator: data.narrator,
          id_guest: data.guest,
          id_source: idSource,
          start_visibility_date: dayjs(newStartDate).format('YYYY-MM-DDTHH:mm:00+02:00'),
          end_visibility_date: dayjs(newEndDate).format('YYYY-MM-DDTHH:mm:00+02:00'),
        }
        return api.putEpisode(subId, d)
      })
    },
    photoUrl: (id, addBaseUrl = true, flavour, subId = null, format = '.jpg') => {
      const token = localStorage.getItem('accessToken')
      return `${addBaseUrl ? config.API_BASE_URL : ''}episodes/${subId}/covers/${flavour}${format}?authorization=${token}`
    },
  },
  delete: {
    text: 'podcasts.delete.confirm',
    getTitle: (item) => item.title,
    submit: (item) => {
      const promise = new Promise((resolve) => {
        api.deletePodcastEpisode(item.id).then(() => {
          resolve(true)
        }, () => {
          resolve(false)
        })
      })
      return promise
    },
  },
}
