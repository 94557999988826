import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, FormInput, Row } from 'shards-react'
import { Podcasts } from '../../../models'
import { List } from '../form'

const EditoSearch = ({ model, addContentFromSearch, isAdded, deactivateAddedNotif }) => {
  const { t } = useTranslation()
  const [term, setTerm] = useState('')
  const [results, setResults] = useState([])
  const [error, setError] = useState(false)

  const search = async () => {
    if (term !== '') {
      try {
        setError(false)
        const data = await model.list.search.submit(term)
        setResults(data)
      } catch (e) {
        setError(true)
      }
    }
  }

  const close = () => {
    setResults([])
    setTerm('')
    setError(false)
  }

  return (
    <div className="search-container">
      <div style={{ marginTop: 10 }}>
        <Row>
          <FormInput
            id="tag"
            type="text"
            style={{
              borderRadius: '.25rem 0 0 .25rem',
              borderRightWidth: 0,
              width: '400px',
            }}
            value={term}
            placeholder={t(model.list.search.placeholder)}
            onKeyPress={(event) => {
              if (event.key === 'Enter' && term !== '') {
                event.preventDefault()
                search()
                deactivateAddedNotif()
              }
            }}
            onChange={(event) => {
              setTerm(event.target.value)
              if (event.target.value === '') {
                close()
                deactivateAddedNotif()
              }
            }}
          />
          <Button
            type="button"
            style={{
              width: '100px',
              borderRadius: '0 .25rem .25rem 0',
            }}
            onClick={() => {
              close()
              search()
              deactivateAddedNotif()
            }}
          >
            {t(model.list.search.submitLabel)}
          </Button>
        </Row>
        {error && (
          <p style={{
            color: 'red',
            marginTop: '5px',
          }}
          >
            {t('editos.search.noContent')}
          </p>
        )}
      </div>
      {results.length > 0 && (
        <div style={{
          position: 'fixed',
          zIndex: 9999,
          width: '89vw',
          left: '10vw',
          marginTop: '10px',
        }}
        >
          <List {...{
            model: Podcasts,
            addTo: addContentFromSearch,
            added: isAdded,
            close,
            dataToShow: results,
          }}
          />
        </div>
      )}
    </div>
  )
}

EditoSearch.propTypes = {
  model: PropTypes.objectOf(PropTypes.any).isRequired,
  addContentFromSearch: PropTypes.func.isRequired,
  isAdded: PropTypes.bool.isRequired,
  deactivateAddedNotif: PropTypes.bool.isRequired,
}

export default EditoSearch
