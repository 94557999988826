import React from 'react'
import { Redirect } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import { Route } from '@simple-contacts/react-router-async-routes'

import PropTypes from 'prop-types'
import { isUserTokenExpired } from '../lib'
import api from '../api'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    async
    {...rest}
    render={async (props) => {
      let accessToken = localStorage.getItem('accessToken')
      const refreshToken = localStorage.getItem('refreshToken')
      if (accessToken && isUserTokenExpired(accessToken)) {
        console.log('token has expired, need to refresh')
        if (refreshToken && !isUserTokenExpired(refreshToken)) {
          console.log('refresh hasn\'t expired, let\'s go to refresh')
          try {
            const user = await api.getToken()
            localStorage.setItem('accessToken', user.access_token)
            localStorage.setItem('refreshToken', user.refresh_token)
          } catch (error) {
            localStorage.clear()
            return (<Redirect to={{ pathname: '/login' }} />)
          }
        } else {
          console.log('refresh has expired, logout user')
          accessToken = null
          localStorage.removeItem('accessToken')
          localStorage.removeItem('refreshToken')
        }
      }
      if (accessToken) {
        const usr = jwtDecode(accessToken)
        if (usr.role === 'ps_user') {
          return (
            <Redirect to={{ pathname: 'login', state: { noAccess: true } }} />
          )
        }
      }
      const redirectPath = accessToken ? '/' : '/login'
      return (
        (accessToken) ? <Component {...props} /> : <Redirect to={{ pathname: `${redirectPath}` }} />
      )
    }}
  />
)

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.string,
    PropTypes.any,
  ]).isRequired,
}

export default PrivateRoute
