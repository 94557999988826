const BASE_URL = {
  dev: 'https://ws-dev.podcaststory.com/',
  uat: 'https://ws-uat.podcaststory.com/',
  prod: 'https://ws.podcaststory.com/',
}

const { REACT_APP_INFRA = 'dev', REACT_APP_PREFIX = '' } = process.env

const API_BASE_URL = BASE_URL[REACT_APP_INFRA]

export default {
  API_BASE_URL,
  REACT_APP_PREFIX,
  LOGIN_URL: '/token',
}
