import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Col } from 'shards-react'

import SidebarMainNavbar from './SidebarMainNavbar'
import SidebarSearch from './SidebarSearch'
import SidebarNavItems from './SidebarNavItems'

import { Store } from '../../../flux'

class MainSidebar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuVisible: false,
      sidebarNavItems: Store.getSidebarItems(),
    }

    this.onChange = this.onChange.bind(this)
  }

  onChange() {
    const state = {
      ...this.state,
      menuVisible: Store.getMenuState(),
      sidebarNavItems: Store.getSidebarItems(),
    }
    this.setState(state)
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    Store.addChangeListener(this.onChange)
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUnmount() {
    Store.removeChangeListener(this.onChange)
  }

  render() {
    const { menuVisible } = this.state
    const { hideLogoText } = this.props
    const classes = classNames(
      'main-sidebar',
      'px-0',
      'col-12',
      menuVisible && 'open',
    )

    return (
      <Col
        tag="aside"
        className={classes}
        lg={{ size: 2 }}
        md={{ size: 3 }}
      >
        <SidebarMainNavbar hideLogoText={hideLogoText} />
        <SidebarSearch />
        <SidebarNavItems />
      </Col>
    )
  }
}

MainSidebar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool,
}

MainSidebar.defaultProps = {
  hideLogoText: false,
}

export default MainSidebar
