import React from 'react'
import {
  Button,
} from 'shards-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const AutomatedModalDelete = ({
  model,
  currentItem,
  closeModal,
  mutationLoading,
  mutationError,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <p style={{ textAlign: 'center' }}>
        {t(model.delete.text)}
        <br />
        <strong style={{ fontSize: '1.2rem' }}>{model.delete.getTitle(currentItem)}</strong>
      </p>
      {!mutationLoading && (
        <div style={{ textAlign: 'center' }}>
          <Button
            theme="danger"
            className="mb-2 mr-1"
            onClick={() => {
              closeModal()
            }}
          >
            {t('ui.deleteNo')}
          </Button>

          <Button
            theme="primary"
            className="mb-2 mr-1"
            onClick={async () => {
              closeModal(true)
            }}
          >
            {t('ui.deleteYes')}
          </Button>
          {mutationError && (<p>{t('ui.deleteError')}</p>)}
        </div>
      )}
    </>
  )
}

AutomatedModalDelete.propTypes = {
  model: PropTypes.objectOf(PropTypes.any).isRequired,
  currentItem: PropTypes.objectOf(PropTypes.any).isRequired,
  closeModal: PropTypes.func.isRequired,
  mutationLoading: PropTypes.bool,
  mutationError: PropTypes.bool,
}

AutomatedModalDelete.defaultProps = {
  mutationLoading: false,
  mutationError: false,
}

export default AutomatedModalDelete
