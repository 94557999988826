import React from 'react'
import PropTypes from 'prop-types'

const PodcastReadEpisodes = ({ items, id }) => (
  <div className="category-container">
    {items && items && items.map((item) => (
      <p key={item.id} style={{ margin: 0 }}>
        <a href={`/podcasts/${id}/episodes/read/${item.id}`}>
          {item.title}
        </a>
      </p>
    ))}
  </div>
)

PodcastReadEpisodes.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  id: PropTypes.number.isRequired,
}

export default PodcastReadEpisodes
