/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ReactQuill from 'react-quill'
import i18n from 'i18next'
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Form,
  FormInput,
  FormGroup,
  FormRadio,
  FormCheckbox,
  FormSelect,
  Button,
  FormFeedback,
  FormTextarea,
} from 'shards-react'
import { useLazyQuery, useMutation } from '@apollo/client'

import { useHistory, useParams } from 'react-router-dom'
import config from '../../../config'

const AutomatedCreate = ({ model }) => {
  const { t } = useTranslation()
  const [data, setData] = useState({})
  const [validField, setValidField] = useState({})
  const [formSubmit, setFormSubmit] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [_, setLoading] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [__, setLoadingData] = useState(false)
  const [error, setError] = useState([])
  const [loadingExtra, setLoadingExtra] = useState(0)
  // used to popupate select, radio, checkbox when data is from api
  const [populatedForm, setPopulatedForm] = useState([])
  const formFromModel = model.create.form

  const params = useParams()
  const history = useHistory()

  const loadDataAsync = async () => {
    setLoading(true)
    let waitingLoad = 0
    const form = {
      ...populatedForm,
    }

    formFromModel.forEach(async (row) => {
      if (row.type === 'row') {
        row.items.forEach(async (field) => {
          if (field.model) {
            waitingLoad += 1
            let dataToPopulate
            if (config.CONNECTOR === 'graphql' && field.model.graphql) {
              dataToPopulate = field.model.populateForm(dataFromGraphQl)
            } else {
              console.log('populate 1')
              dataToPopulate = await field.model.populateForm(i18n.language)
            }
            form[field.id] = dataToPopulate
            setPopulatedForm(form)
            waitingLoad -= 1
            if (waitingLoad === 0) {
              setLoading(false)
            }
          }
        })
      } else if (row.model) {
        waitingLoad += 1
        let dataToPopulate
        if (config.CONNECTOR === 'graphql' && model.graphql) {
          dataToPopulate = row.model.populateForm(dataFromGraphQl)
        } else {
          console.log('populate 2')
          dataToPopulate = await row.model.populateForm(i18n.language)
        }
        form[row.id] = dataToPopulate
        setPopulatedForm(form)
        waitingLoad -= 1
        if (waitingLoad === 0) {
          setLoading(false)
        }
      }
    })
  }

  const populateFormAfterApi = (item) => {
    const tData = {
      ...data,
    }

    formFromModel.forEach(async (row) => {
      if (row.type === 'row') {
        row.items.forEach((field) => {
          if (field.id && item[field.id]) {
            tData[field.id] = item[field.id]
            setData(tData)
          }
        })
      } else if (row.id && item[row.id]) {
        tData[row.id] = item[row.id]
        setData(tData)
      }
    })
    setLoading(false)
  }

  const submitModel = (id) => {
    let loading = 0
      const err = [
        ...error,
      ]
      formFromModel.forEach(async (row) => {
      if (row.type === 'row') {
        row.items.forEach(async (field) => {
          if (field.needToSubmit) {
            try {
              loading += 1
              setLoadingExtra(loading)
              await field.needToSubmit(id, data[field.id])
            } catch (e) {
              console.log(e)
              err.push(`ui.custom.${field.id}`)
              setError(err)
            } finally {
              loading -= 1
              setLoadingExtra(loading)
            }
          }
        })
      } else if (row.needToSubmit) {
        try {
          loading += 1
          setLoadingExtra(loading)
          await row.needToSubmit(id, data[row.id])
        } catch (e) {
          console.log(e)
          err.push(`ui.custom.${row.id}`)
          setError(err)
        } finally {
          loading -= 1
          setLoadingExtra(loading)
        }
      }
    })
  }

  useEffect(() => {
    console.log(formSubmit, loadingExtra, model.url)
    if (formSubmit && loadingExtra === 0) {
      history.push({
        pathname: model.url && typeof model.url === 'function' ? model.url(params.id) : model.url,
        state: { create: true },
      })
    }
  }, [loadingExtra])

  if (config.CONNECTOR === 'graphql' && model.graphql) {
    // eslint-disable-next-line no-unused-vars
    const [submitGraphQl,
      // eslint-disable-next-line no-unused-vars
      { loading: mutationLoading, error: mutationError },
    ] = useMutation(model.create.submitQuery, {
      variables: !model.graphql ? {} : model.create.submit(data),
      onCompleted: ((dt) => {
        const id = model.create.getIdAfterSubmit(dt)
        submitModel(id)
      }),
    })
  }

  if (config.CONNECTOR === 'graphql' && model.graphql) {
    // eslint-disable-next-line no-unused-vars
    const [fetchGrahQlItem] = useLazyQuery(model.create.query, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (itemToUpdate) => {
        populateFormAfterApi(model.create.transformQuery(itemToUpdate))
        loadDataAsync(itemToUpdate)
      },
    })
  }

  // const loadItemAsync = async () => {
  //   setLoadingData(true)
  //   if (config.CONNECTOR === 'graphql' && model.graphql) {
  //     // eslint-disable-next-line no-undef
  //     fetchGrahQlItem()
  //   } else {
  //     // const item = await model.create.getItem(i18n.language)
  //     populateFormAfterApi(item)
  //   }
  // }

  useEffect(() => {
    if (config.CONNECTOR !== 'graphql' && !model.graphql) {
      loadDataAsync()
    }
  }, [])

  const isValidField = (field, value) => {
    if (!field.id) {
      return true
    }

    let valid = true
    if (field.required) {
      if (field.type === 'select') {
        valid = value !== field.defaultValue && !!value
      } else if (value === '') {
        valid = false
      } else {
        valid = !!value

        if (field.regex && valid) {
          valid = field.regex.test(value)
        }
      }
    }
    if (
      field.id === 'start_visibility_date' &&
      data.end_visibility_date &&
      data.start_visibility_date &&
      new Date(data.end_visibility_date) < new Date(data.start_visibility_date)
    ) {
      console.log('')
      valid = false
    }
    return valid
  }

  const onChangeCustom = (id, value) => {
    const tData = {
      ...data,
      [id]: value,
    }
    setData(tData)
  }

  const submitForm = async (event) => {
    event.preventDefault()
    setFormSubmit(true)

    let couldSendForm = true
    const tvalidField = {
      ...validField,
    }

    formFromModel.forEach((row) => {
      if (row.type === 'row') {
        row.items.forEach((field) => {
          tvalidField[field.id] = isValidField(field, data[field.id])
          if (!tvalidField[field.id]) {
            couldSendForm = false
          }
        })
      } else {
        tvalidField[row.id] = isValidField(row, data[row.id])
        if (!tvalidField[row.id]) {
          couldSendForm = false
        }
      }
    })

    setValidField(tvalidField)

    if (couldSendForm) {
      if (config.CONNECTOR === 'graphql' && model.graphql) {
        // eslint-disable-next-line no-undef
        submitGraphQl()
      } else {
        let loading = 0
        let noExtra = true
        const err = [
          ...error,
        ]
        try {
          await model.create.submit(data, i18n.language, history, params)
        } catch (e) {
          const tvalidTitle = {
            ...tvalidField,
            title: false,
          }
          setValidField(tvalidTitle)
          return
        }

        formFromModel.forEach(async (row) => {
          if (row.type === 'row') {
            row.items.forEach(async (field) => {
              if (field.needToSubmit) {
                noExtra = false
                try {
                  loading += 1
                  setLoadingExtra(loading)
                  await field.needToSubmit(id, data[field.id])
                } catch (e) {
                  console.log(e)
                  err.push(`ui.custom.${field.id}`)
                  setError(err)
                } finally {
                  loading -= 1
                  setLoadingExtra(loading)
                }
              }
            })
          } else if (row.needToSubmit) {
            try {
              noExtra = false
              loading += 1
              setLoadingExtra(loading)
              await row.needToSubmit(id, data[row.id])
            } catch (e) {
              console.log(e)
              err.push(`ui.custom.${row.id}`)
              setError(err)
            } finally {
              loading -= 1
              setLoadingExtra(loading)
            }
          }
        })

        if (noExtra) {
          history.push({
            pathname: model.url && typeof model.url === 'function' ? model.url(params.id) : model.url,
            state: { create: true },
          })
        }
      }
    }
  }

  const makeMyInput = (field) => {
    switch (field.type) {
      case 'select':
        return (
          <>
            <label htmlFor={`fe${field.id}`}>
              {t(field.label)}
              {field.required && (<span>*</span>)}
            </label>
            <FormSelect
              id={`fe${field.id}`}
              value={data[field.id]}
              valid={!!validField[field.id] && field.showOk && formSubmit}
              invalid={!validField[field.id] && formSubmit}
              onChange={((event) => {
                const tData = {
                  ...data,
                  [field.id]: event.target.value,
                }
                setData(tData)
                const tvalidField = {
                  ...validField,
                  [field.id]: isValidField(field, event.target.value),
                }
                setValidField(tvalidField)
              })}
            >
              <option>{t(field.defaultValue)}</option>
              {field.data && field.data.map((option, index) => (
                <option value={option.value} key={`option${index}`}>{option.label}</option>
              ))}
              {populatedForm[field.id] && populatedForm[field.id].map((option, index) => (
                <option value={option.value} key={`option${index}`}>{option.label}</option>
              ))}
            </FormSelect>
            <FormFeedback valid>{t('form.fieldValid')}</FormFeedback>
            <FormFeedback invalid>{t('form.fieldInvalid')}</FormFeedback>
          </>
        )
      case 'wysiwyg':
        return (
          <>
            <label htmlFor={`fe${field.id}`}>
              {t(field.label)}
              {field.required && (<span>*</span>)}
            </label>
            <ReactQuill
              id={`fe${field.id}`}
              value={data[field.id] ? data[field.id] : ''}
              className="add-new-post__editor mb-1"
              onChange={(value) => {
                const tData = {
                  ...data,
                  [field.id]: value,
                }
                setData(tData)
              }}
            />
          </>
        )
      case 'textarea':
        return (
          <>
            <label htmlFor={`fe${field.id}`}>
              {t(field.label)}
              {field.required && (<span>*</span>)}
            </label>
            <FormTextarea
              id={`fe${field.id}`}
              valid={!!validField[field.id] && field.showOk && formSubmit}
              invalid={!validField[field.id] && formSubmit}
              value={data[field.id]}
              rows="5"
              onChange={(event) => {
                const tData = {
                  ...data,
                  [field.id]: event.target.value,
                }
                setData(tData)

                const tvalidField = {
                  ...validField,
                  [field.id]: isValidField(field, event.target.value),
                }
                setValidField(tvalidField)
              }}
            />
            <FormFeedback invalid>{t('form.fieldInvalid')}</FormFeedback>
          </>
        )
      case 'checkbox':
        return (
          <>
            <FormCheckbox
              checked={data[field.id]}
              value={data[field.id]}
              valid={!!validField[field.id] && field.showOk && formSubmit}
              invalid={!validField[field.id] && formSubmit}
              onChange={(() => {
                const tData = {
                  ...data,
                  [field.id]: !data[field.id],
                }
                setData(tData)

                const tvalidField = {
                  ...validField,
                  [field.id]: isValidField(field, !data[field.id]),
                }
                setValidField(tvalidField)
              })}
            >
              {field.label && (<>{t(field.label)}</>)}
              {field.labelWithLink && (
              <span>
                {field.labelWithLink.map((label, index) => (
                  <span key={`label${index}`}>
                    {label.link ? (
                      <a href={label.link} target={label.target} rel="noopener noreferrer">{t(label.text)}</a>
                    ) : (
                      <>{t(label.text)}</>
                    )}
                  </span>
                ))}
              </span>
              )}
            </FormCheckbox>
            <FormFeedback valid>{t('form.fieldValid')}</FormFeedback>
            <FormFeedback invalid>{t('form.fieldInvalid')}</FormFeedback>
          </>
        )
      case 'checkboxes':
        return (
          <>
            <label htmlFor={`fe${field.id}`}>{t(field.label)}</label>
            { !validField[field.id] && formSubmit && (
              <FormFeedback
                invalid
                style={{ display: 'block', marginBottom: '10px', marginTop: '-5px' }}
              >
                {t('form.fieldInvalid')}
              </FormFeedback>
            )}
            {field.data && field.data.map((box, index) => (
              <span key={`multi-checkbox${field.id}-${index}`}>
                { makeMyInput({
                  type: 'multi-checkbox',
                  id: field.id,
                  label: box.label,
                  value: box.value
                  ,
                })}
              </span>
            ))}
            {populatedForm[field.id] && populatedForm[field.id].map((box, index) => (
              <span key={`multi-checkbox${field.id}-${index}`}>
                { makeMyInput({
                  type: 'multi-checkbox',
                  id: field.id,
                  label: box.label,
                  value: box.value
                  ,
                })}
              </span>
            ))}
          </>
        )
      case 'multi-checkbox':
        return (
          <FormCheckbox
            checked={data[field.id] && data[field.id].indexOf(field.value) !== -1}
            onChange={(() => {
              const validatedFields = data[field.id] || []
              const pos = validatedFields.indexOf(field.value)
              if (pos === -1) {
                validatedFields.push(field.value)
              } else {
                validatedFields.splice(pos, 1)
              }

              const tData = {
                ...data,
                [field.id]: validatedFields,
              }
              setData(tData)

              const tvalidField = {
                ...validField,
                [field.id]: isValidField(field, !data[field.id]),
              }
              setValidField(tvalidField)
            })}
          >
            {field.label && (<>{t(field.label)}</>)}
          </FormCheckbox>
        )
      case 'radios':
        return (
          <>
            <label htmlFor={`fe${field.id}`}>{t(field.label)}</label>
            { !!validField[field.id] && formSubmit && (
              <FormFeedback
                invalid
                style={{ display: 'block', marginBottom: '10px', marginTop: '-5px' }}
              >
                {t('form.fieldInvalid')}
              </FormFeedback>
            )}
            {field.data && field.data.map((box, index) => (
              <span key={`radio${field.id}-${index}`}>
                { makeMyInput({
                  type: 'radio',
                  id: field.id,
                  label: box.label,
                  value: box.value
                  ,
                })}
              </span>
            ))}
            {populatedForm[field.id] && populatedForm[field.id].map((box, index) => (
              <span key={`radio${field.id}-${index}`}>
                { makeMyInput({
                  type: 'radio',
                  id: field.id,
                  label: box.label,
                  value: box.value
                  ,
                })}
              </span>
            ))}
          </>
        )
      case 'radio':
        return (
          <FormRadio
            checked={data[field.id] === field.value}
            onChange={(() => {
              const tData = {
                ...data,
                [field.id]: field.value,
              }
              setData(tData)
            })}
          >
            {field.label && (<>{t(field.label)}</>)}
          </FormRadio>
        )
      case 'submit':
        return (
          <>
            <Button
              type="submit"
              className={field.className}
              onClick={submitForm}
            >
              {t(field.label)}
            </Button>
            {/* {(mutationLoading || loadingExtra > 0) && (<p>{t('ui.mutationLoading')}</p>)}
            {mutationError && (<p>{t('ui.mutationErrorCreate')}</p>)} */}
            {error && error.length > 0 && error.map((err, index) => (
              <p key={index}>{t(err)}</p>
            ))}
          </>
        )
      case 'button':
        return (
          <Button
            type="button"
            className={field.className}
            onClick={() => {
              if (field.history) {
                history.push(field.history)
              }
            }}
          >
            {t(field.label)}
          </Button>
        )
      case 'back':
        return (
          <Button
            theme="secondary"
            className={field.className}
            onClick={() => {
              history.push(model.url && typeof model.url === 'function' ? model.url(params.id) : model.url)
            }}
          >
            {t(field.label)}
          </Button>
        )
      case 'custom':
        return (
          <>
            <label htmlFor={`fe${field.id}`}>
              {t(field.label)}
              {field.required && (<span>*</span>)}
            </label>
            {field.component({
              model,
              id: null,
              data,
              onChangeCustom,
              field,
            })}
          </>
        )
      default:
        return (
          <>
            <label htmlFor={`fe${field.id}`}>
              {t(field.label)}
              {field.required && (<span>*</span>)}
            </label>
            <FormInput
              id={`fe${field.id}`}
              type={field.type}
              value={data[field.id]}
              valid={!!validField[field.id] && field.showOk && formSubmit}
              invalid={!validField[field.id] && formSubmit}
              placeholder={t(field.placeholder)}
              max={field.id === 'start_visibility_date' && data.end_visibility_date && data.end_visibility_date}
              min={field.id === 'end_visibility_date' && data.start_visibility_date && data.start_visibility_date}
              onChange={((event) => {
                const tData = {
                  ...data,
                  [field.id]: event.target.value,
                }
                setData(tData)

                const tvalidField = {
                  ...validField,
                  [field.id]: isValidField(field, event.target.value),
                }
                setValidField(tvalidField)
              })}
            />
            <FormFeedback valid>{t('form.fieldValid')}</FormFeedback>
            <FormFeedback invalid>
              {field.id === 'title' && (
                data[field.id] ? (
                  t('form.titleInvalid')
                ) : (
                  t('form.fieldInvalid')
                  )
              )}
              {field.id !== 'title' && (
                t('form.fieldInvalid')
              )}
              {field.id === 'start_visibility_date' &&
                data.end_visibility_date &&
                data.end_visibility_date &&
                new Date(data.end_visibility_date).getTime() <
                  new Date(data.start_visibility_date).getTime() && (
                  <>
                    <br />
                    {t('dateError.errorInferior')}
                  </>
                )}
              {field.id === 'end_visibility_date' &&
              data.start_visibility_date &&
              data.end_visibility_date &&
              new Date(data.end_visibility_date).getTime() <
                new Date(data.start_visibility_date).getTime() && (
                <>
                  <br />
                  {t('dateError.errorSupperior')}
                </>
              )}
            </FormFeedback>
          </>
        )
    }
  }

  return (
    <>
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">
                {t(model.create.title)}
              </h6>
            </CardHeader>
            <CardBody className="p-0 pb-3">
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form>
                        {formFromModel.map((item, indexRow) => (
                          <div key={`row${indexRow}`}>
                            {item.type === 'row' ? (
                              <Row form>
                                {item.items.map((field, index) => (
                                  <Col md={field.md} className="form-group" key={`col${index}`}>
                                    { makeMyInput(field) }
                                  </Col>
                                ))}
                              </Row>
                            ) : (
                              <FormGroup>
                                { makeMyInput(item) }
                              </FormGroup>
                            )}
                          </div>
                        ))}
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

AutomatedCreate.propTypes = {
  model: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default AutomatedCreate
