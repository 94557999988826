import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { ReactVideo } from 'reactjs-media'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button } from 'shards-react'
import api from '../../../api'
import config from '../../../config'

const PodcastReadTeaser = ({ onChangeCustom, field }) => {
  const inputFile = useRef(null)
  const { t } = useTranslation()
  const { id } = useParams()
  const [loaded, setLoaded] = useState(false)
  const [teasers, setTeasers] = useState([])
  const [teasersToShow, setTeasersToShow] = useState([])
  const token = localStorage.getItem('accessToken')

  // eslint-disable-next-line no-unused-vars
  const [file, setFile] = useState()
  // eslint-disable-next-line no-unused-vars
  const [fileLoaded, setFileLoaded] = useState()

  useEffect(() => {
    if (id) {
      api.getPodcastTeasers(id).then((data) => {
        const ts = []
        const tshow = []
        data.forEach((teaser) => {
          const video = api.getPodcastTeaser(id, teaser, token)
          ts.push({ teaser, video, action: 'none' })
          tshow.push(video)
        })
        setTeasers(ts)
        setTeasersToShow(tshow)
        setLoaded(true)
      })
    }
  }, [])

  const loadFile = (e) => {
    const selectedFile = e.target.files[0]
    setFile(selectedFile)
    if (!selectedFile.type.startsWith('video/')) { return }

    const preview = document.getElementById('preview-video')

    const video = document.createElement('video')
    video.setAttribute('controls', 'controls')
    video.setAttribute('width', '300px')
    const source = document.createElement('source')
    video.appendChild(source)
    preview.appendChild(video)

    // eslint-disable-next-line no-undef
    const reader = new FileReader()
    reader.onload = (event) => {
      setFileLoaded(event.target.result)

      source.src = event.target.result
      video.load()

      const ts = [
        ...teasers,
        { video: event.target.result, action: 'added' },
      ]
      setTeasers(ts)
      onChangeCustom(field.id, ts)
    }
    reader.readAsDataURL(selectedFile)
  }

  return (
    <div className="category-container">
      {teasersToShow.map((teaser, index) => (
        <div
          key={`teaser${index}`}
          style={{ marginBottom: '20px' }}
        >
          <ReactVideo
            src={`${config.API_BASE_URL}${teaser}`}
            poster="/assets/poster.png"
            primaryColor="blue"
            // other props
          />
          <Button
            type="button"
            style={{
              width: '200px',
              borderRadius: '0 .25rem .25rem 0',
            }}
            onClick={() => {
              const ts = []
              teasers.forEach((teas, i) => {
                const tmp = {
                  ...teas,
                }
                if (i === index) {
                  tmp.action = 'deleted'
                }

                ts.push(tmp)
              })
              setTeasers(ts)
              onChangeCustom(field.id, ts)

              const tshow = [
                ...teasersToShow,
              ]
              tshow.splice(index, 1)
              setTeasersToShow(tshow)
            }}
          >
            {t('podcasts.label.deleteTeasers')}
          </Button>
        </div>
      ))}
      {loaded && teasers.length === 0 && (
        <p>{t('podcasts.ui.noTeasers')}</p>
      )}

      <Button
        theme="primary"
        className="mb-2 mr-1"
        onClick={() => {
          inputFile.current.click()
        }}
      >
        {t('podcasts.ui.addTeaser')}
      </Button>
      <input
        type="file"
        id="file"
        accept="video/*"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={loadFile}
      />
      <div id="preview-video" />
    </div>
  )
}

PodcastReadTeaser.propTypes = {
  onChangeCustom: PropTypes.func,
  field: PropTypes.objectOf(PropTypes.any).isRequired,
}

PodcastReadTeaser.defaultProps = {
  onChangeCustom: () => {},
}

export default PodcastReadTeaser
